import { Language, Translation } from "../../model/ConfigurationEntities";
import { TLanguageApi } from "./ApiTypes";
import { toBooleanResult, toRawResult } from "./Results";
import * as Api from "./TactinApi";

const MODULE = "LanguageModule";

function toLanguageList(result: Api.ServerActionResult): Promise<Language[]> {
    return new Promise((resolve, reject) => {
        const rawResult = toRawResult(result);
        if (!rawResult)
            reject(new Api.ExecutionError("Wrong response from server!"));
        else
            resolve(rawResult.values as Language[]);
    });
}

function toLanguage(result: Api.ServerActionResult): Promise<Language> {
    return new Promise((resolve, reject) => {
        const rawResult = toRawResult(result);
        if (!rawResult)
            reject(new Api.ExecutionError("Wrong response from server!"));
        else
            resolve(rawResult.value as Language);
    });
}

function toTranslationList(result: Api.ServerActionResult): Promise<Translation[]> {
    return new Promise((resolve, reject) => {
        const rawResult = toRawResult(result);
        if (!rawResult)
            reject(new Api.ExecutionError("Wrong response from server!"));
        else
            resolve(rawResult.values as Translation[]);
    });
}

export const LanguageApi = (token: string): TLanguageApi => ({
    getLanguages: (translations: boolean) =>
        Api.sendRequest(MODULE, "getLanguages", { translations }, token).then(r => toLanguageList(r)),
    saveLanguage: (language: Language) =>
        Api.sendRequest(MODULE, "saveLanguage", { language }, token).then(r => toLanguage(r)),
    removeLanguage: (id: number) =>
        Api.sendRequest(MODULE, "removeLanguage", { id }, token).then(r => toBooleanResult(r)),
    getTranslations: (id: number) =>
        Api.sendRequest(MODULE, "getTranslations", { id }, token).then(r => toTranslationList(r)),
    saveTranslation: (translation: Translation, langId: number) =>
        Api.sendRequest(MODULE, "saveTranslation", { translation, langId }, token).then(r => toLanguage(r)),
    removeTranslation: (id: number) =>
        Api.sendRequest(MODULE, "removeTranslation", { id }, token).then(r => toBooleanResult(r))
});
