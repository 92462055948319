import { MessageNotification, QuestionNotification, SaveBeforeNotification, SystemActionExecutor } from "../../components/systemactions/SystemAction";
import { api } from "../../utils/api/ApiProvider";
import { Failed } from "../../utils/api/TactinApi";
import { WithWaitNotify } from "../../utils/WaitNotification";
import { copyAsNew, moveToClientSpace } from "../item/Item";
import { ClientItem, Item } from "../item/ItemTypes";
import { ActionContextProvider, ItemActionContext } from "./ActionContext";

export function CreateTemplateSystemAction(contextProvider: ActionContextProvider): SystemActionExecutor {
    return async () => {
        const context = contextProvider('item');

        const createTemplate = (item: Item) => {
            const template = copyAsNew(item, true);
            return {
                type: 'replace',
                data: {
                    item: template
                }
            }
        }

        if (context && context instanceof ItemActionContext) {
            if (context.id > 0) {
                const resume = async (item?: Item) => {
                    return createTemplate(item ?? context.item.item);
                }

                return SaveBeforeNotification('Niektóre pola zostały zmienione. '
                    + 'Niezapisane zmiany zostaną utracone. Czy chcesz zapisać '
                    + 'obiekt przed utworzeniem szablonu?',
                    resume);
            } else
                return createTemplate(context.item.item);
        }

        return null;
    }
}

export function CreateItemFromTemplateSystemAction(configuration: any, contextProvider: ActionContextProvider): SystemActionExecutor {
    return async () => {

        const deepCopy = (configuration?.type ?? 'deep') === 'deep';

        const context = contextProvider('item');

        const createInstance = (template: Item): Promise<ClientItem> => {
            if (deepCopy)
                return Promise.resolve<ClientItem>({ item: copyAsNew(template, false), permission: 'delete' });
            else
                return api().Item.getNewItemFromTemplate(template.itemID);
        }

        if (!context || !(context instanceof ItemActionContext))
            return null;
        if (context.id <= 0)
            return MessageNotification('Nie można utworzyć obiektu z nie zapisanego szablonu!');

        const template = context.item.item;

        const resume = async (item?: Item) => {
            let result: ClientItem;
            if (item)
                result = await createInstance(item);
            else
                result = await createInstance(template);

            if (!deepCopy)
                moveToClientSpace(result.item);
            return {
                type: 'replace',
                data: {
                    clientItem: result
                }
            }
        }

        return SaveBeforeNotification('Niektóre pola zostały zmienione. Niezapisane '
            + 'zmiany zostaną utracone. Czy chcesz zapisać obiekt '
            + 'przed utworzeniem szablonu?',
            resume);
    }
}

export function IndividualizeItemSystemAction(contextProvider: ActionContextProvider, categoryId?: number): SystemActionExecutor {
    return async () => {
        const context = contextProvider('item');
        if (!context)
            return null;
        if (context.id <= 0)
            return MessageNotification('Nie można usamodzielnić niezapisanego obiektu.');

        try {
            const result = await WithWaitNotify(api().Item.individualizeItem(context.id, categoryId), 'Indywidualizacja w toku...');
            return {
                type: 'replace',
                data: {
                    clientItem: result
                }
            }
        } catch (e) {
            if (e instanceof Failed)
                return MessageNotification(e.message);
            throw e;
        }
    }
}

export function RestoreSharedItemSystemAction(contextProvider: ActionContextProvider, categoryId?: number): SystemActionExecutor {
    return async () => {
        const context = contextProvider('item');
        if (!context || context.id <= 0)
            return MessageNotification('Nie można odtworzyć niezapisanego obiektu.');

        const resume = async () => {
            try {
                const result = await WithWaitNotify(api().Item.restoreItem(context.id), 'Przywracanie w toku...');
                return {
                    type: 'replace',
                    data: {
                        clientItem: result
                    }
                }
            } catch (e) {
                if (e instanceof Failed)
                    return MessageNotification(e.message);
                throw e;
            }
        }

        return QuestionNotification('Przywrócenie współdzielenia obiektu spowoduje utratę '
            + 'wszystkich zmian dokonanych w tym obiekcie. Czy '
            + 'jesteś pewiem, że chcesz przywrócić współdzielenie?',
            resume);
    }
}
