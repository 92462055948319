export type ListProperties = {
    hasItemId: boolean;
    columnNames: string[];
    columnAggregation: string[];
    columnWidths: string[];
    columnGrouping: string[];
}

export function getRowCount(properties: ListProperties): number {
    if(properties.columnAggregation.length)
        return Number(properties.columnAggregation[properties.columnAggregation.length - 1]);
    else
        return 0;
}

export type ChangeList = {
    createdItems: string[];
    modifiedItems: string[];
    timestamp: number;
}