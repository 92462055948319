import { Editor, TinyMCE } from "tinymce";

export function customUnlinkAddOn(editor: Editor) {
    const onClick = () => {
        const node = editor.selection.getNode();
        if (node.tagName === 'A') {
            const value = node.textContent as string;
            editor.dom.remove(node);
            editor.selection.setContent(value);
        }
    }

    editor.ui.registry.addButton('unlink', {
        icon: 'unlink',
        tooltip: 'remove link',
        onAction: () => { onClick() }
    });
    editor.ui.registry.addMenuItem('unlink', {
        text: 'unlink',
        onAction: () => { onClick() }
    });
}

export default (tinymce: Editor) =>
    (tinymce.editorManager as TinyMCE).PluginManager.add('unlink', customUnlinkAddOn);
