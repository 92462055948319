import React from 'react';
import { ROW_KEY_DELIMITER } from '../../../model/list/cache/DataCache';
import { FoldableRow } from '../../../model/list/cache/RowParser';
import { tactin } from '../../../utils/TactinGlobals';
import TableCell, { TableCellProps } from './TableCell';

const GROUPING_ITEM_ID_SEPARATOR = '↔';

type ListCellProps = {
    data: string | FoldableRow;
    subCount?: number;
    groupColumn?: boolean;
    onFold?: (meta: string) => void;
    onMouseOver?: (fullText: string) => void;
    onMouseOut?: (fullText: string) => void;
}

export default function ListCell(props: TableCellProps<ListCellProps>) {
    let content: JSX.Element | null = null;
    let text: string = '';
    if (typeof props.data === 'string') {
        text = props.data
        content = <>{text}</>;
    } else {
        const index = props.data.showAs.indexOf(GROUPING_ITEM_ID_SEPARATOR);
        text = index < 0 ? props.data.showAs : props.data.showAs.slice(index + 1);
        if (!text)
            text = tactin().configuration.translate('<empty>');
        const spanCount = props.data.meta.split(ROW_KEY_DELIMITER, -1).length - 1;
        let padding: any = null;
        if (spanCount > 0) {
            padding = [];
            for (let i = 0; i < spanCount; i++) {
                padding.push(<span key={i} className='list-level-padding' />)
            }
        }
        content = (<>
            {padding}
            {props.subCount ? <button
                className={`group-expand${props.data.opened ? ' expanded' : ''}`}
                onClick={
                    () => typeof props.data !== 'string' && props.onFold && props.onFold(props.data.meta)}>
                {props.data.opened ? '-' : '+'}
            </button> : null}
            <span>{text}</span>
        </>);
    }
    return (<TableCell className={props.groupColumn ? 'grouped' : ''} width={props.width} canGrow={props.canGrow}
        onMouseOver={e => props.onMouseOver && props.onMouseOver(text)}
        onMouseOut={e => props.onMouseOut && props.onMouseOut(text)}>
        {content}
    </TableCell>);
}
