export type Property = {
    id: number;
    name: string;
    description: string;
    type: PropertyType;
    defaultValue: string;
    configuration?: object;
    used: string[];
}

export type PropertyType = 'TEXT' | 'DECIMAL' | 'DATE' | 'ITEM2ITEM' | 'ITEM2MANYITEMS' | 'INTEGER' |
    'BIT' | 'VALUELIST' | 'FILELINK' | 'IMAGE' | 'RICHTEXT' 
    //system values
    | 'CATEGORY' | 'TYPE' | 'USER';

export const PropertyTypeNames: {[type: string]: string} = {
    ['TEXT']: 'Text',
    ['DECIMAL']: 'Decimal',
    ['DATE']: 'Date',
    ['ITEM2ITEM']: 'Item to item',
    ['ITEM2MANYITEMS']: 'Item to many items',
    ['INTEGER']: 'Integer',
    ['BIT']: 'Checkbox',
    ['VALUELIST']: 'Value list',
    ['FILELINK']: 'File link',
    ['IMAGE']: 'Image',
    ['RICHTEXT']: 'Rich text area'
};

export function toPropertyType(text: string) : PropertyType | null {
    if(typeof PropertyTypeNames[text] === 'string')
        return text as PropertyType;
    return null;
}

export function emptyProperty() : Property {
    return {
        id: 0,
        name: '',
        type: 'TEXT',
        description: '',
        defaultValue: '',
        configuration: {},
        used: []
    }
}

export const validateProperty = (property: Property) => {
    if(!property.name)
        return 'property must have a name';
    if(property.configuration === undefined)
        return 'property must have a valid JSON configuration';
    return '';
}
