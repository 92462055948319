const SPECIAL_COLS = [ 'inner_id', 'row_style', 'owner_id', 'show_as' ]

export type RowData = {
    columns: (string | FoldableRow)[];
    id?: number;
    showAs?: string;
    rowStyle?: string;
    ownerId?: number;
    subCount?: number;
}

export type FoldableRow = {
    opened: boolean;
    meta: string;
    showAs: string;
}

export type RowParserFn = (row: string[]) => RowData;

export function rowParser(columns: string[]): RowParserFn {
    const idColumn = columns.findIndex(c => c === 'inner_id');
    const showAsColumn = columns.findIndex(c => c === 'show_as');
    const rowStyle = columns.findIndex(c => c === 'row_style');
    const ownerIdColumn = columns.findIndex(c => c === 'owner_id');
    const colCount = columns.filter(c => !SPECIAL_COLS.includes(c)).length;
    const backupShowAs = columns.reduce((id, col, i) => {
        if(id >= 0 || SPECIAL_COLS.includes(col))
            return id;
        return i;
    }, -1);
    let showAs = -1;
    if(showAsColumn > -1)
        showAs = showAsColumn;
    else if(backupShowAs > -1)
        showAs = backupShowAs;

    return (row: string[]): RowData => ({
        columns: row.slice(0, colCount),
        id: idColumn > -1 ? Number(row[idColumn]) : undefined,
        showAs: showAs > -1 ? row[showAs] : undefined,
        rowStyle: rowStyle > -1 ? row[rowStyle] : '',
        ownerId: ownerIdColumn > -1 ? Number(row[ownerIdColumn]) : undefined,
        subCount: row.length > columns.length ? Number(row[row.length - 1]) : undefined
    });
}
