import { TactinEvent } from "./TactinEvents";

export type TactinEventListener = (event: TactinEvent) => void;
export type TTactinEventBus = {
    register: (handler: TactinEventListener) => (() => void);
    notify: (event: TactinEvent) => void;
}

export default class TactinEventBus implements TTactinEventBus {
    listeners: TactinEventListener[] = [];
    events: TactinEvent[] = [];
    sender: number = 0;

    register(handler: TactinEventListener) {
        for (const listner of this.listeners)
            if(listner === handler)
                throw new Error('Event listener registered twice!');
        this.listeners.push(handler);
        return () => this.unregister(handler);
    }

    notify(event: TactinEvent) {
        this.events.push(event);
        this.sendNotifications();
    }

    private unregister(handler: TactinEventListener) {
        this.listeners = this.listeners.filter(l => l !== handler);
    }

    private sendNotifications() {
        if(this.sender || this.events.length === 0)
            return;
        this.sender = window.setTimeout(() => {
            const event = this.events.shift();
            if(event)
                for (const handler of this.listeners)
                    handler(event);
            this.sender = 0;
            this.sendNotifications();
        }, 0);
    }
}
