import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { TLocalEventBus } from '../../../model/events/LocalEventBus';
import { CloseItemEvent, OpenItemEvent } from '../../../model/events/TactinEvents';
import { FoldableRow, RowData } from '../../../model/list/cache/RowParser';
import { HandleErrors } from '../../../utils/api/ApiErrorHandler';
import { api } from '../../../utils/api/ApiProvider';
import { tactin } from '../../../utils/TactinGlobals';
import { TableFooter, TableHeader, TablePadding } from './Headers';
import ListRow from './ListRow';
import './ListTable.css';

export type GroupingHandler = { updateGroup: (name: string) => void, defaultGroups: string[], groups: string[] }
export type SortingHandler = (name: string) => { updateSort: (name: string) => void, sortColumn: string, sortDirection: 'ASC' | 'DESC' }

export function useSortingHandler() {
    const [sorting, setSorting] = useState<{ name: string, direction: 'ASC' | 'DESC' }>({ name: '', direction: 'ASC' });

    const updateSorting = (name: string) => {
        if (name !== sorting.name)
            setSorting({ name, direction: 'ASC' })
        else if (sorting.direction === 'ASC')
            setSorting({ name: sorting.name, direction: 'DESC' });
        else
            setSorting({ name: '', direction: 'ASC' });
    }

    const sortHandler: SortingHandler = (name: string) => ({
        updateSort: updateSorting,
        sortColumn: sorting.name,
        sortDirection: sorting.direction
    });

    return { sorting, sortHandler };
}

type ListTableProps = {
    rowCount: number;
    rowHeight: number;

    columns: string[];
    widths: string[];
    aggregations: string[];
    showDescription: boolean;

    getData: (start: number, length: number) => RowData[];

    onFoldToggle?: (meta: string) => void;

    eventBus?: TLocalEventBus;
    onClick: (row: RowData) => void;
    onArrowButton?: (row: RowData) => void;
    onDblClick: (row: RowData) => void;

    groupable?: GroupingHandler;
    sortable?: SortingHandler;

    decorate: boolean;
    footerFields?: (rowCount: number, range: { start: number, length: number }) => JSX.Element[];
}

export function ListTable(props: ListTableProps) {

    const translate = tactin().configuration.translate;
    const tbodyRef = useRef<HTMLDivElement>(null);
    const showAsRef = useRef<HTMLSpanElement>(null);
    const descriptBlock = useRef<HTMLDivElement>(null);
    const descriptContent = useRef<HTMLDivElement>(null);

    const [start, setStart] = useState(0);
    const [visibleRows, setVisibleRows] = useState(1);
    const [bottomPadding, setBottomPadding] = useState(0);

    const [selectedRow, selectRow] = useState(-1);
    const [selectedRowId, selectRowId] = useState(0);
    const [itemDescription, setItemDescription] = useState('');
    const isClicked = useRef(false);
    const timerRef = useRef(0);
    const isSnapScrollToRow = useRef(true);
    const ifKeyDown = useRef(false);

    useEffect(() => {
        return () => {
            if (timerRef.current)
                window.clearTimeout(timerRef.current);
        }
    }, [])

    useLayoutEffect(() => {
        if (!window.sessionStorage.descriptContentHeight && tbodyRef.current)
            window.sessionStorage.descriptContentHeight = tbodyRef.current.offsetHeight / 3;
        if (descriptContent.current)
            descriptContent.current.style.height = window.sessionStorage.descriptContentHeight + 'px';
        tableResized(Math.max(0, (tbodyRef.current?.offsetHeight || 0)));
    }, []);

    useLayoutEffect(() => {
        if (selectedRow >= 0) {
            focusOnRow(selectedRow);
            setValue();
        }
    }, [selectedRow]);

    const setLoadingItemDescription = () => setItemDescription(`<p>...${translate('Loading')}</p>`);

    const setValue = () => {
        const { dataRow } = getDataRow(selectedRow, 1);
        const id = dataRow.id as number;
        if (id !== selectedRowId) {
            if (isClicked.current) {
                props.onClick(dataRow);
                selectRowId(id);
                isClicked.current = false;
            } else {
                if (props.showDescription) {
                    const callback = () => {
                        selectRowId(id);
                        setLoadingItemDescription();
                    }
                    isSnapScrollToRow.current = false;
                    useTimer(callback, true);
                }
                else {
                    props.onArrowButton && props.onArrowButton(dataRow);
                    selectRowId(id);
                }
            }
        }
    }

    const onClick = (globalIndex: number) => {
        selectRow(globalIndex);
        isClicked.current = true;
        if (props.showDescription)
            setLoadingItemDescription();
    }

    useEffect(() => {
        if (props.eventBus)
            return props.eventBus.register(localEventHandler);
    }, [props.eventBus]);

    const getDataRow = (index: number, length: number) => {
        const [dataRow] = props.getData(index, length);
        const toggleInfo = dataRow.columns.find(c => typeof c === 'object') as FoldableRow;
        return { dataRow, toggleInfo }
    }

    const onEnterClick = () => {
        const { dataRow, toggleInfo } = getDataRow(selectedRow, 1);
        const isTree = !props.groupable;
        if (!dataRow.subCount || isTree)
            props.onDblClick(dataRow);
        else
            if (toggleInfo && props.onFoldToggle)
                props.onFoldToggle(toggleInfo.meta);
    }

    const onMoveUp = () => (selectedRow > 0) && selectRow(selectedRow - 1);
    const onMoveDown = () => (selectedRow < props.rowCount - 1) && selectRow(selectedRow + 1);

    const deploy = () => {
        const { dataRow, toggleInfo } = getDataRow(selectedRow, 1);
        if (dataRow.subCount) {
            if (!toggleInfo.opened && props.onFoldToggle)
                props.onFoldToggle(toggleInfo.meta)
        }
    }

    const fold = () => {
        const { dataRow, toggleInfo } = getDataRow(selectedRow, 1);
        const isRowGroup = dataRow.subCount !== undefined;
        if (isRowGroup) {
            if (toggleInfo.opened && props.onFoldToggle)
                props.onFoldToggle(toggleInfo.meta);
            else if (!toggleInfo.opened && props.onFoldToggle) {
                const rowStory = toggleInfo.meta.split('»');
                if (rowStory.length > 0)
                    selectParentRow(rowStory);
            }
        } else
            selectParentRow();
    }

    const selectParentRow = (rowStory?: string[]) => {
        for (let i = selectedRow - 1; i >= 0; i--) {
            const { toggleInfo } = getDataRow(i, 1);
            if (toggleInfo && toggleInfo.opened) {
                if (rowStory) {
                    const parentName = rowStory[rowStory.length - 2];
                    const upperRowStory = toggleInfo.meta.split('»');
                    const upperRowName = upperRowStory[upperRowStory.length - 1];
                    if (parentName === upperRowName) {
                        selectRow(i);
                        break;
                    }
                } else {
                    selectRow(i);
                    break;
                }
            }
        }
    }

    const keyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        e.preventDefault();
        const executor = () => {
            switch (e.key) {
                case 'Enter': onEnterClick(); break;
                case 'ArrowUp': onMoveUp(); break;
                case 'ArrowDown': onMoveDown(); break;
                case 'ArrowRight': deploy(); break;
                case 'ArrowLeft': fold(); break;
                default: return;
            }
        }
        if (ifKeyDown.current) {
            e.persist();
            setTimeout(() => executor(), 50)
        } else {
            ifKeyDown.current = true;
            executor();
        }
    }

    const keyUp = (e: React.KeyboardEvent<HTMLDivElement>) => ifKeyDown.current = false;

    const localEventHandler = (type: string, data: any) => {
        switch (type) {
            case 'onEnterClick': onEnterClick(); break;
            case 'onMoveUp': onMoveUp(); break;
            case 'onMoveDown': onMoveDown(); break;
            case 'focus': focus(data || false); break;
            case 'resetSelect': selectRow(-1); break;
        }
    }

    const focus = (resetHighlight: boolean) => {
        if (resetHighlight)
            selectRow(0);
        tbodyRef.current?.focus();
    }
    const focusOnRow = (rowNo: number) => {
        if (rowNo > -1 && (rowNo < start || rowNo > start + visibleRows - 1))
            tbodyRef.current?.scrollTo(0, Math.max(0, rowNo - Math.floor(visibleRows / 2)) * props.rowHeight);
    }

    useEffect(() => {
        const resizeListener = () => {
            tableResized(Math.max(0, (tbodyRef.current?.offsetHeight || 0)));
        };
        addEventListener('resize', resizeListener);
        return () => {
            removeEventListener('resize', resizeListener);
        }
    })

    useEffect(() => {
        if (start > props.rowCount - visibleRows)
            setStart(Math.max(0, props.rowCount - visibleRows));
    }, [start, visibleRows, props.rowCount]);

    const tableResized = (innerHeight: number) => {
        const newRowCount = Math.max(1, props.rowHeight && Math.floor(innerHeight / props.rowHeight));
        setBottomPadding(innerHeight - newRowCount * props.rowHeight);
        if (newRowCount !== visibleRows) {
            setVisibleRows(newRowCount);
            if ((newRowCount + start > props.rowCount) && start > 0)
                setStart(Math.max(0, props.rowCount - newRowCount));
        }
    }

    const mouseButtonRef = useRef(false);
    useEffect(() => {
        const handler = (event: any) => {
            if (event.button === 0) {
                mouseButtonRef.current = event.type === 'mousedown';
                if (!mouseButtonRef.current && tbodyRef.current) {
                    if (Math.abs((tbodyRef.current.scrollTop / props.rowHeight) - start) > 0.1)
                        snapToRow();
                }
            }
        };
        window.addEventListener('mousedown', handler);
        window.addEventListener('mouseup', handler);
        return () => {
            window.removeEventListener('mousedown', handler);
            window.removeEventListener('mouseup', handler);
        }
    }, [props.rowHeight, start]);

    const snapToRow = () => {
        let startPos = (tbodyRef.current?.scrollTop ?? 0) / props.rowHeight;
        startPos = Math.round(startPos);
        tbodyRef.current?.scrollTo(0, startPos * props.rowHeight);
    }

    const useTimer = (executor: () => void, isKeybord: boolean = false) => {
        if (timerRef.current)
            window.clearTimeout(timerRef.current);
        timerRef.current = window.setTimeout(() => {
            timerRef.current = 0;
            isSnapScrollToRow.current = true;
            executor();
        }, isKeybord ? 500 : 200);
            }

    const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        if (isSnapScrollToRow.current && !mouseButtonRef.current)
            useTimer(() => snapToRow())
        setStart(Math.round(event.currentTarget.scrollTop / props.rowHeight));
    }

    useEffect(() => {
        if (props.showDescription && selectedRowId)
            api().Item.getItemDescription(selectedRowId)
                .then(r => setItemDescription(r))
                .catch(HandleErrors())
    }, [selectedRowId])

    useEffect(() => {
        setItemDescription('');
        selectRowId(0);
    }, [props])
    // for executing after 30 sec if data was updated

    useEffect(() => {
        if (descriptBlock.current && descriptContent.current) {
            const mousedown = (e: MouseEvent) => {
                const element = descriptContent.current as HTMLDivElement;
                const prevHeight = element.offsetHeight;
                let prevY = e.clientY;

                const mousemove = (e: MouseEvent) => {
                    const tbodyHeight = tbodyRef.current?.offsetHeight as number;
                    if (tbodyHeight < 40 && (prevY - e.clientY) > 0) return;
                    element.style.height = prevHeight + (prevY - e.clientY) + 'px';
                    window.sessionStorage.descriptContentHeight = prevHeight + (prevY - e.clientY);
                    useTimer(() => tableResized(Math.max(0, (tbodyRef.current?.offsetHeight || 0))));
                }
                const mouseup = (e: MouseEvent) => {
                    window.removeEventListener('mousemove', mousemove, false);
                    window.removeEventListener('mouseup', mouseup, false);
                }
                window.addEventListener('mousemove', mousemove, false);
                window.addEventListener('mouseup', mouseup, false);
            }

            descriptBlock.current.addEventListener('mousedown', mousedown);
            return () => descriptBlock.current?.removeEventListener('mousedown', mousedown);
        }
    })

    // set focus to the List after Item was closed
    const openedCards = useRef(0);
    useEffect(() => {
        return tactin().eventBus.register((event) => {
            if (event instanceof OpenItemEvent)
                openedCards.current = openedCards.current++;
            else if (event instanceof CloseItemEvent) {
                openedCards.current = openedCards.current--;
                if (openedCards.current === 0)
                    tbodyRef.current?.focus()
            }
        })
    }, [])

    return (<div className='table' >
        <TableHeader columns={props.columns} widths={props.widths} groupable={props.groupable} sortable={props.sortable} />
        <div className='tbody' ref={tbodyRef}
            onScroll={onScroll}
            tabIndex={1}
            onKeyDown={keyDown} onKeyUp={keyUp}>
            <TablePadding height={props.rowHeight * start} />
            {props.getData(start, visibleRows)
                .map((r, i) => {
                    const globalInd = start + i;
                    return <ListRow
                        key={globalInd}
                        data={r}
                        columns={props.columns}
                        widths={props.widths}
                        groups={props.groupable?.groups}
                        onFoldToggle={props.onFoldToggle}
                        isSelectedRow={selectedRow === globalInd}
                        onMouseOver={text => showAsRef.current && (showAsRef.current.textContent = text)}
                        onMouseOut={text => showAsRef.current && (showAsRef.current.textContent === text) && (showAsRef.current.textContent = '')}
                        onClick={() => onClick(globalInd)}
                        onDblClick={() => props.onDblClick(r)} />
                })}
            <TablePadding height={props.rowHeight * Math.max(0, props.rowCount - start - visibleRows) + bottomPadding} />
        </div>
        {(props.decorate && props.aggregations.reduce((a, b) => a || !!b, false)) ?
            <TableFooter columns={props.columns} widths={props.widths} aggregations={props.aggregations} />
            : null}
        {props.decorate && props.showDescription &&
            <div ref={descriptBlock} className='description-block'>
                <div className='resizer'></div>
                <p className='label' >{translate('Description')}</p>
                <div ref={descriptContent} className='content' dangerouslySetInnerHTML={{ __html: itemDescription }} />
            </div>
        }
        {(props.decorate && props.footerFields) ?
            (<div className='tr footer status-footer'>
                {props.footerFields(props.rowCount, { start, length: Math.min(props.rowCount, visibleRows) })}
                <span ref={showAsRef} key='showAs'></span>
            </div>)
            : null}
    </div>);
}

type MockRowProps = {
    onSizeSet: (size: number) => void;
}

export function MockRow(props: MockRowProps) {
    const ref = useRef<HTMLTableRowElement>(null);

    useLayoutEffect(() => {
        if (ref.current)
            props.onSizeSet(ref.current.offsetHeight || 1);
    });

    return (<div className='table mock-row'><div className='tbody'>
        <div className='tr' ref={ref}><div className='td'>.</div></div>
    </div></div>);
}
