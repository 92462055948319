import React from 'react';
import PopupBox from './PopupBox';

type Props = {
    title: string,
    message: string,
    onClose?: () => void
}

function MessageBox( {title, message, onClose}: Props ) {
    return (
    <PopupBox
            title={<span>{title}</span>}
            buttons={[
                (onClickCreator) => (<div key={1} className="button" onClick={onClickCreator()}>Ok</div>)
            ]}
            onClose={(s?: string) => onClose && onClose()} >
        <p>{message}</p>
    </PopupBox>);
}

export default MessageBox;