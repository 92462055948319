import React from 'react'
import { ClientItem, Item, SeriesAddOn } from '../../../model/item/ItemTypes'
import { CardSubPanelWrapper } from '../CardSubPanelWrapper';
import SeriesGenerateDate from './SeriesGenerateDate';
import SeriesModifiedDate from './SeriesModifiedDate';

type SimplifiedSeriesPanel = {
    original: ClientItem;
    itemData: ClientItem;
    configuration: any;
    onChange: (item: Item) => void;
}

export function SimplifiedSeriesPanel({ original, itemData, configuration, onChange }: SimplifiedSeriesPanel) {
    const series = itemData.item.addOns?.series;

    if (!series)
        return null;

    const modifiedField = Object.getOwnPropertyNames(series.modifyConfig)[0] ?? series.generationConfig.field;
    if (!modifiedField)
        return null;

    const changeSeries = (s: Partial<SeriesAddOn>) =>
        onChange({
            ...itemData.item,
            addOns: {
                ...itemData.item.addOns,
                series: {
                    ...series,
                    ...s
                }
            }
        });

    const changeModifiedDate = (v: any) =>
        changeSeries({
            modifyConfig: {
                ...(series.modifyConfig ?? {}),
                [modifiedField]: v
            }
        });


    const changeGenerateDate = (v: any) =>
        changeSeries({
            generationConfig: {
                field: series.generationConfig.field ?? modifiedField,
                fieldConfig: v
            }
        });

    return <CardSubPanelWrapper configuration={configuration}
        toolbarDefaults={original.item.addOns?.series ? ['restoreSeries'] : []}>
        <SeriesModifiedDate
            value={series.modifyConfig[modifiedField] || { type: 'interval', interval: { type: 'day', subtype: 'after', interval: 1 } }}
            onChange={changeModifiedDate} />
        <SeriesGenerateDate
            value={{ dateMode: 'static', staticDate: '2099-12-31', ...(series?.generationConfig.fieldConfig ?? {}) }}
            onChange={changeGenerateDate} />
    </CardSubPanelWrapper>
}
