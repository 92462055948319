import React, { useEffect, useRef, useState } from 'react';
import { TLocalEventBus } from '../../model/events/LocalEventBus';
import { CloseItemEvent, OpenItemEvent, ReplaceItemEvent } from '../../model/events/TactinEvents';
import DataCache from '../../model/list/cache/DataCache';
import { GroupDataCache } from '../../model/list/cache/GroupCache';
import { RowData } from '../../model/list/cache/RowParser';
import { ListProperties } from '../../model/list/HelperTypes';
import { ActionContextProvider } from '../../model/systemactions/ActionContext';
import { tactin } from '../../utils/TactinGlobals';
import UIFactory from '../core/UIFactory';
import { ListToolbar } from '../toolbars/ListToolbar';
import { GroupingHandler, ListTable, MockRow, SortingHandler } from './ListTable/ListTable';

type ListViewerProps = {
    listProperties: ListProperties;
    cacheCreator: (prp: ListProperties) => DataCache;

    groupable?: GroupingHandler;
    sortable?: SortingHandler;
    saveOpenedGroups: (value: Map<string, number>) => void;

    eventBus?: TLocalEventBus;
    onSelect?: (id: number, showAs: string, highlight?: boolean) => void;
    openOnDblClick: boolean;

    decorate?: boolean;
    footer?: (rowCount: number, range: { start: number, length: number }) => JSX.Element[];
    toolbarUsagePlace: number;
    toolbarConfiguration: string[];
    showDescription: boolean;

    getContextProvider?: () => ActionContextProvider;
}

export function ListViewer(props: ListViewerProps) {
    const cache = useRef<DataCache>();
    const { decorate = true } = props;

    const cacheUpdater = useRef<number>();
    const CACHE_UPDATE_INTERVAL = 30 * 1000;
    const cacheLoader = useRef<number>();
    const CACHE_LOADER_INTERVAL = 300;

    const [reload, setReload] = useState({});

    const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
    const [rowHeight, setRowHeight] = useState(0);

    const toggleFold = (meta: string) => {
        cache.current?.toggleFold(meta);
        cache.current?.openedSet && props.saveOpenedGroups(cache.current.openedSet);
        setReload({});
    }

    const cleanup = (keeper: React.MutableRefObject<number | undefined>) => {
        if (keeper.current !== undefined)
            window.clearTimeout(keeper.current);
    }

    useEffect(() => () => {
        cleanup(cacheUpdater);
        cleanup(cacheLoader);
        cache.current = undefined;
    }, [])

    const scheduleCacheUpdater = () => {
        cleanup(cacheUpdater);
        cacheUpdater.current = window.setTimeout(() => {   //without the window, TS takes the setTimeout from node and has a type mismatch
            cache.current?.needsUpdateAsync();
        }, CACHE_UPDATE_INTERVAL);
    }

    const scheduleDataLoad = () => {
        cleanup(cacheLoader);
        cacheLoader.current = window.setTimeout(() => {
            cache.current && cache.current.loadMissingData();
        }, CACHE_LOADER_INTERVAL);
    }

    const openedCards = useRef(0);

    useEffect(() => {
        return tactin().eventBus.register((event) => {
            if (event instanceof ReplaceItemEvent) {
                // we can use here a controller to update the list on each Save 
                // for example: tactin().configuration.systemInfo('preventCacheReload') === 'always' and then update()
            }
            else if (event instanceof OpenItemEvent) {
                if (openedCards.current === 0)
                    cleanup(cacheUpdater);
                openedCards.current = openedCards.current++;
            }
            else if (event instanceof CloseItemEvent) {
                openedCards.current = openedCards.current--;
                if (openedCards.current === 0) {
                    cache.current?.needsUpdateAsync();
                    cleanup(cacheUpdater);
                    scheduleDataLoad();
                }
            }
        })
    }, [])

    useEffect(() => {
        cache.current = props.cacheCreator(props.listProperties);
        cache.current.onDataChanged = () => {
            if (cache.current)
                setReload({})
        }
        cache.current.cacheUpdatedCallback = {
            error: () => scheduleCacheUpdater(),
            cachedReloaded: () => {
                setReload({});
                scheduleCacheUpdater();
            },
            cacheUpToDate: () => scheduleCacheUpdater(),
            cacheOutdated: () => cache.current?.reloadCache(true)
        };

        if (props.listProperties.columnNames.length)
            setVisibleColumns(props.listProperties.columnNames.filter(c => c.startsWith('"')));

        scheduleCacheUpdater();

        return cleanup(cacheUpdater);
    }, [props.listProperties]);

    const getData = (start: number, length: number) => {
        const output: RowData[] = (cache.current && length) ? cache.current.getData(start, length) : [];
        scheduleDataLoad();
        return output;
    }

    const toolbarDefaults = UIFactory.getStandardOptionList(props.toolbarConfiguration);
    const contextProvider = props.getContextProvider ? props.getContextProvider() : undefined;

    const open = (r: RowData) => {
        console.log(r);
        if (!props.openOnDblClick)
            r.id && props.onSelect && props.onSelect(r.id, r.showAs ?? '', true);
        else if (r.ownerId || r.id)
            tactin()?.eventBus.notify(new OpenItemEvent().byItemId(r.ownerId || r.id || 0))
    }
    const onSelect = (id: number, showAs: string, flag = false) => {
        props.onSelect&&props.onSelect(id, showAs,flag);
    };

    const findId = (r:RowData):number => {
        if (r.id)
            return r.id;
        if (props.listProperties.columnNames.find(v=>v=='"id"')&&typeof r.columns[0] === "string"){
            const v = (r.columns[props.listProperties.columnNames.indexOf('"id"')]);
            if (typeof v === "string"){
                return Number.parseInt(v);
            }
        }
        return 0;
    }
    if (rowHeight > 0) {
        return (<div className='item-list'>
            {decorate ? <ListToolbar usagePlace={props.toolbarUsagePlace} defaultControls={toolbarDefaults} contextProvider={contextProvider} /> : null}
            <ListTable
                rowCount={cache.current?.getVisibleRowCount() || 0}
                rowHeight={rowHeight}
                columns={visibleColumns}
                widths={props.listProperties.columnWidths || []}
                getData={getData}
                onFoldToggle={toggleFold}
                eventBus={props.eventBus}
                onClick={r => findId(r) && onSelect(findId(r), r.showAs ?? '', true)}
                onArrowButton={r => findId(r) && onSelect(findId(r), r.showAs ?? '', false)}
                onDblClick={open}
                groupable={props.groupable} sortable={props.sortable}
                aggregations={props.listProperties.columnAggregation.slice(0, visibleColumns.length)}
                footerFields={props.footer}
                decorate={decorate}
                showDescription={props.showDescription} />
        </div>);
    } else {
        return <MockRow onSizeSet={setRowHeight} />;
    }
}

export function useListSegmentSize() {
    const str = tactin().configuration.systemInfo('singlerequestrowcount') || '1';
    return (Number(str) || 1);
}
