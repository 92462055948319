import React, { useEffect, useRef, useState } from 'react';
import { Option } from '../../model/Interface';

type Props = {
    tabs: Option[];
    selected: number;
    onSelected: (index: number) => void;
}

export default function ScrollableMenuList(props: Props) {
    const $ref = useRef<HTMLDivElement>(null);
    const [range, setRange] = useState({start: 0, length: props.tabs.length});

    useEffect(() => resizeHandler(), [props.tabs.length])

    const resizeHandler = () => {
        if(!$ref.current)
            return;
        if($ref.current.firstElementChild) {
            const width = $ref.current.getBoundingClientRect().width;
            const btnWidth = $ref.current.firstElementChild.getBoundingClientRect().width;
            const count = Math.floor(width / btnWidth);
            setNewCount(Math.max(count, 1));
        }
    }

    const setNewCount = (count: number) => {
        setRange(prev => {
            if(prev.start + count > props.tabs.length)
                return {
                    start: Math.max(0, props.tabs.length - count),
                    length: Math.min(count, props.tabs.length)
                };
            else if(props.selected >= prev.start + count)
                return {
                    start: Math.max(0, props.selected - count + 1),
                    length: count
                };
            else
                return {
                    start: prev.start,
                    length: count
                };
        })
    }
    const scrollLeft = () =>
        range.start > 0 && setRange(prev => ({
                start: prev.start - 1,
                length: prev.length
            }));
    const scrollRight = () =>
        range.start + range.length < props.tabs.length && setRange(prev => ({
                start: prev.start + 1,
                length: prev.length
            }));
    const select = (index: number) => {
        if(index === props.selected)
            return;
        props.onSelected(index);
    }

    useEffect(() => {
        window.addEventListener('resize', resizeHandler, true);
        return () =>
            window.removeEventListener('resize', resizeHandler, true);
    });

    const hiddenStyle = range.length === props.tabs.length ? {display: 'none'} : {};
    return <div className='scrollable-menu-list'>
        <div className='button left'
            onClick={scrollLeft}
            style={hiddenStyle}>{'<'}</div>
        <div className='tab-list' ref={$ref}>
            {props.tabs
            .map((s, i) =>
                <div key={i}
                    className={'tab-position' + (props.selected === i ? ' selected' : '')}
                    onClick={_ => select(i)}>
                        <img className='image' src={`img/${s.icon}.png`}/>
                        <span className='label'>{s.name}</span>
                </div>)
            .filter((s, i) => i >= range.start && i < (range.start + Math.max(1, range.length)))}
        </div>
        <div className='button right'
            onClick={scrollRight}
            style={hiddenStyle}>{'>'}</div>
    </div>
}