import { Item } from "../model/item/ItemTypes";
import { ItemUtils } from "../model/item/Utils";

export default {
    getBuiltInEmptyWildcards,
    substituteWildcards,
}

/**
 * Returns a new wildcard map prepopulated with the built-in wildcards.
 *
 * <p>This method serves two purposes. Its implementation provides a lookup
 * of all built-in wildcards, so it's easy to check what keys are needed to
 * be implemented on the client/server side.</p>
 *
 * <p>Secondly, using it as a base for wildcard handling, assures that the
 * built-in wildcards will be handled semi-gracefully - they will be
 * replaced with empty string if no other value is provided.</p>
 *
 * @return new map of built-in wildcards with empty string as values
 */
function getBuiltInEmptyWildcards(): Map<string, string> {
    const result = new Map<string, string>();
    result.set('now', '');
    result.set('today', '');
    return result;
}

/**
 * Substitute the wildcard entries in the text with the ones provided in the
 * map.
 *
 * @param text text to be filled with wildcards
 * @param wildcards map of available wildcards
 * @param user user item used in substitution
 * @return text filled with wildcard values
 */
function substituteWildcards(text: string, wildcards: Map<string, string>, user?: Item): string {
    const combined = new Map<string, string>();
    if(user?.addOns?.user)
        loadWildcards(combined, user.addOns.user.userWildcards);
    wildcards.forEach((value, key) => combined.set(key, value));

    let lastEnd = 0;
    let sb = '';
    const allWildcards = getAllWildcards(text);
    for(const w of allWildcards) {
        sb += text.substring(lastEnd, w.start);
        switch (w.type) {
            case 'SIMPLE':
            case 'SIMPLE_DEFAULT':
                if(combined.has(w.name))
                    sb += combined.get(w.name);
                else if(w.type === 'SIMPLE_DEFAULT')
                    sb += w.defaultValue;
                break;
        case 'CODE':
        case 'CODE_DEFAULT':
            if(combined.has(w.name))
                sb += w.code.replaceAll('$$', combined.get(w.name) || '').replaceAll('\\$', '$');
            else if(w.type === 'CODE_DEFAULT')
                sb += w.defaultValue;
        case 'USER_FIELD':
            if(user) {
                const val = ItemUtils.getFieldValue(user, w.name);
                if(val !== undefined)
                    sb += String(val);
            }
        }
        lastEnd = w.start + w.length;
    }
    sb += text.substring(lastEnd);

    return sb;
}

function loadWildcards(wildcardMap: Map<string, string>, wildcardStrings: string[]) {

    for(const w of wildcardStrings)
    {
        const keyValue = w.trim().split(':', 2);
        if(keyValue.length != 2)
            continue;
        wildcardMap.set(keyValue[0], keyValue[1].trim());
    }
}

type Wildcard = {
    type: 'SIMPLE' | 'SIMPLE_DEFAULT' | 'CODE' | 'CODE_DEFAULT' | 'USER_FIELD';
    start: number;
    length: number;
    name: string;
    code: string;
    defaultValue: string;
}

function wildcardType(regex: string, handler: (w: Wildcard, match: RegExpMatchArray) => void) {
    return ({
        regex: new RegExp('\\$\\{' + regex + '\\}', 'g'),
        map: (match: RegExpMatchArray): Wildcard => {
            const result: Wildcard = {
                start: match.index || 0,
                length: match[0].length,
                type: 'SIMPLE',
                name: '',
                code: '',
                defaultValue: ''
            }
            handler(result, match);
            return result;
        }
    })
}


const wildcardTypes = [
    wildcardType('([a-zA-Z0-9_]+)', (w, match) => {
        w.type = 'SIMPLE';
        w.name = match[1];
    }),
    wildcardType('([a-zA-Z0-9_]+):([^}]*)', (w,match) => {
        w.type = 'SIMPLE_DEFAULT';
        w.name = match[1];
        w.defaultValue = match[2];
    }),
    wildcardType('([a-zA-Z0-9_]+)`([^`]+)`', (w, match) => {
        w.type = 'CODE';
        w.name = match[1];
        w.code = match[2];
    }),
    wildcardType('([a-zA-Z0-9_]+)`([^`]+)`:(`([^`]+)`)?', (w, match) => {
            w.type = 'CODE_DEFAULT';
            w.name = match[1];
            w.code = match[2];
            if(match.length > 3)
                w.defaultValue = match[4];
            else
                w.defaultValue = '';
    }),
    wildcardType('user\\.([a-zA-Z0-9_]+)', (w, match) => {
            w.type = 'USER_FIELD';
            w.name = match[1];
    }),
];

function getAllWildcards(text: string): Wildcard[] {
    const result: Wildcard[] = [];

    for(const type of wildcardTypes) {
        for(const match of text.matchAll(type.regex))
            result.push(type.map(match));
    }

    return result.sort((a, b) => a.start - b.start);
}
