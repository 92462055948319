import { SystemInfo, SystemInfoConverter } from '../../model/ConfigurationEntities';
import { ClientConfigData } from '../../model/TactinConfig';
import { TAdminApi, TUpdateInfo, TUpgradeResult } from './ApiTypes';
import { toGetOptionsResult, toRawResult, toSerializableObjectArrayResult, toSerializableObjectResult } from './Results';
import * as Api from './TactinApi';

const MODULE = 'AdminModule';

function toSystemInfoList(result: Api.ServerActionResult): Promise<SystemInfo[]> {
    return new Promise((resolve, reject) => {
        const rawResult = toRawResult(result);
        if (!rawResult)
            reject(new Api.ExecutionError('Wrong response from server!'));
        else {
            const vals = rawResult.values || [];
            resolve(vals.map(v => SystemInfoConverter.fromApi(v)));
        }
    });
}

export type DatabaseInfo = {
    current: number;
    required: number;
}

export const AdminApi = (token: string): TAdminApi => ({
    getSystemConfigurationKeys: () =>
        Api.sendRequest(MODULE, 'getSystemConfigurationKeys', {}, token).then(r => toSystemInfoList(r)),
    saveSystemConfigurationKeys: (si: SystemInfo) =>
        Api.sendRequest(MODULE, 'saveSystemConfigurationKeys', SystemInfoConverter.toApi(si), token).then(sar => sar.resultCode === 'OK'),
    removeSystemConfigurationKey: (key: string) =>
        Api.sendRequest(MODULE, 'removeSystemConfigurationKeys', { key }, token).then(sar => sar.resultCode === 'OK'),
    getClientConfigData: () =>
        Api.sendRequest(MODULE, 'getClientConfigData', {}, token).then(sar => toSerializableObjectResult<ClientConfigData>(sar, 'ClientConfigData')),

    getAdminPanelOptions: () =>
        Api.sendRequest(MODULE, 'getAdminPanelOptions', {}, token).then(toGetOptionsResult),
    getAvailableUpdatesInfo: () =>
        Api.sendRequest(MODULE, 'getAvailableUpdatesInfo', {}, token).then(sar => toSerializableObjectArrayResult<TUpdateInfo>(sar, 'UpdateInfo')),
    updateDatabaseToVersion: (targetVersion: number) =>
        Api.sendRequest(MODULE, 'updateDatabaseToVersion', { targetVersion }, token).then(sar => toSerializableObjectArrayResult<TUpgradeResult>(sar, 'UpgradeResult')),

    reloadValidators: () => Api.sendRequest(MODULE, 'reloadValidators', {}, token).then(sar => sar.resultCode === 'OK'),
    reloadPlugins: () => Api.sendRequest(MODULE, 'reloadPlugins', {}, token).then(sar => sar.resultCode === 'OK'),
    reloadPermissions: () => Api.sendRequest(MODULE, 'reloadPermissions', {}, token).then(sar => sar.resultCode === 'OK'),
    reloadShowAs: () => Api.sendRequest(MODULE, 'reloadShowAs', {}, token).then(sar => sar.resultCode === 'OK'),
    reloadCategories: () => Api.sendRequest(MODULE, 'reloadCategories', {}, token).then(sar => sar.resultCode === 'OK'),
    splitPermissionGroups: () => Api.sendRequest(MODULE, 'splitPermissionGroups', {}, token).then(sar => sar.resultCode === 'OK')
});

export const PreLogAdminApi = () => ({
    getDatabaseInfo: () =>
        Api.sendRequest(MODULE, 'getDatabaseInfo', {}).then(sar => toSerializableObjectResult<DatabaseInfo>(sar, 'DatabaseInfo'))
});
