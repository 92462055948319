import { Category, CategoryConverter, Report, TextTemplate } from "../../model/ConfigurationEntities";
import { DataProvider, DataProviderConverter } from "../../model/DataProvider";
import { ItemType } from "../../model/ItemType";
import { PermissionGroup, PermissionGroupConverter } from "../../model/PermissionGroup";
import { Property } from "../../model/Property";
import { ApiConverter, SaveResult, TypedApi } from "./ApiTypes";
import { RawResult, toRawResult } from "./Results";
import * as Api from "./TactinApi";

const MODULE = "ConfigurationModule";

const transformArray = <T>(converter: ApiConverter<T>, values?: any[]) => {
    if (!values)
        return [];
    return values.map(v => converter.fromApi(v));
}

export const toList = <T>(result: Api.ServerActionResult, converter?: ApiConverter<T>): Promise<T[]> =>
    parseResult(r => {
        if (converter)
            return transformArray<T>(converter, r.values);
        else
            return r.values as T[];
    })(result);

export const toSave = <T>(result: Api.ServerActionResult, converter?: ApiConverter<T>): Promise<SaveResult<T>> =>
    parseResult(r => {
        let newList: T[];
        if (converter)
            newList = transformArray<T>(converter, r.value.newList);
        else
            newList = r.value.newList as T[] || [];
        return { id: r.value.id as number, list: newList };
    })(result);

export const toEntity = <T>(result: Api.ServerActionResult, converter?: ApiConverter<T>): Promise<T> =>
    parseResult(r => {
        if (converter)
            return converter.fromApi(r.value);
        else
            return r.value as T;
    })(result);

export const toUsage = <T>(result: Api.ServerActionResult): Promise<object> =>
    parseResult(r => r.value as object)(result);

export function parseResult<T>(doSuccess: (result: RawResult) => T) {
    return (result: Api.ServerActionResult) =>
        new Promise((resolve: (value: T) => void, reject) => {
            const rawResult = toRawResult(result);
            if (!rawResult)
                reject(new Api.ExecutionError("Wrong response from server!"));
            else
                resolve(doSuccess(rawResult));
        });
}

function list<T>(type: string, token: string, converter?: ApiConverter<T>) {
    return Api.sendRequest(MODULE, "list", { type }, token).then(r => toList<T>(r, converter));
}
function save<T>(entity: T, type: string, token: string, converter?: ApiConverter<T>, original?: T) {
    return Api.sendRequest(MODULE, "save", { type, entity: (converter ? converter.toApi(entity, original) : entity) }, token).then(r => toSave<T>(r, converter));
}
function saveAll<T>(entities: [T, T?][], type: string, token: string, converter?: ApiConverter<T>) {
    return Api.sendRequest(MODULE, "save", { type, entities: entities.map(e => (converter ? converter.toApi(e[0], e[1]) : e[0])) }, token).then(r => toSave<T>(r, converter));
}
function remove<T>(id: number, type: string, token: string, converter?: ApiConverter<T>) {
    return Api.sendRequest(MODULE, "remove", { type, id }, token).then(r => toList<T>(r, converter));
}
function usage<T>(id: number, type: string, token: string) {
    return Api.sendRequest(MODULE, "usage", { type, id }, token).then(r => toUsage<T>(r));
}

function typedApi<T>(token: string, entityType: string, converter?: ApiConverter<T>): TypedApi<T> {
    return {
        list: () =>
            list(entityType, token, converter),
        save: (entity, original) =>
            save(entity, entityType, token, converter, original),
        saveAll: (entities) =>
            saveAll(entities, entityType, token, converter),
        remove: (id) =>
            remove(id, entityType, token, converter),
        usage: (id) =>
            usage(id, entityType, token)
    }
}

export type ApiGenerator<T> = (token: string) => TypedApi<T>;

export const TextTemplateApi: ApiGenerator<TextTemplate> = token => ({
    ...typedApi(token, "TextTemplate"),
    usage: (id) => Promise.resolve({})
});
export const ReportApi: ApiGenerator<Report> = (token) => ({
    ...typedApi(token, "Report"),
    usage: (id) => Promise.resolve({})
});
export const PropertyApi: ApiGenerator<Property> = (token) => typedApi(token, "Property");
export const ItemTypeApi: ApiGenerator<ItemType> = (token) => typedApi(token, "ItemType");
export const DataProviderApi = (token: string) => ({
    ...typedApi<DataProvider>(token, "DataProvider", DataProviderConverter),
    usage: (id: number) => Promise.resolve({})
});
export const PermissionGroupApi = (token: string) => typedApi<PermissionGroup>(token, "PermissionGroup", PermissionGroupConverter);
export const CategoryApi = (token: string) => typedApi<Category>(token, "Category", CategoryConverter);
