import React, { useEffect, useState } from 'react';
import { ClientItem, Item, SeriesAddOn } from '../../model/item/ItemTypes';

type Props = {
    itemData: ClientItem;
    field: keyof SeriesAddOn;
    onChange: (item: Item) => void;
}

export default function SeriesConfigTemp({ itemData, field, onChange }: Props) {
    const getValue = () =>
        itemData.item.addOns?.series?.[field] ? JSON.stringify(itemData.item.addOns?.series?.[field]) : '';

    const [value, setValue] = useState(getValue);

    const change = (value: string) => {
        try {
            const obj = JSON.parse(value);
            if(itemData.item.addOns?.series)
                onChange({
                    ...itemData.item,
                    addOns: {
                        ...itemData.item.addOns,
                        series: {
                            ...itemData.item.addOns.series,
                            [field]: obj
                        }
                    }
                });
        }
        catch(e) {
            setValue(value);
        }
    }

    useEffect(() => setValue(getValue()), [itemData]);

    return <div>
        <textarea value={value}
            onChange={e => change(e.currentTarget.value)} />
    </div>;
}
