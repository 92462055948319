import UIFactory from "../components/core/UIFactory";
import { api } from "../utils/api/ApiProvider";
import { LoginResult } from "../utils/api/Results";
import { TextTemplate } from "./ConfigurationEntities";

export type ClientConfigData = {
    sessionTimeout: number;
    systemInfo: { [key: string]: string };
    categoryUsagePlaces: { [key: string]: number };
    categoryTemplateUsagePlaces: { [key: string]: number };
    standardItemCardUsagePlaces: { [key: string]: number };
    standardTemplateCardUsagePlaces: { [key: string]: number };
    translation: { [key: string]: string };
    richTextTemplates: TextTemplate[];
    itemSeriesDefaultConfig: string;
    itemSeriesSimplifiedInterface: boolean;
}

export type TactinConfigData = {
    clientConfig: ClientConfigData;
    standardHandlers: Map<string, string>;
}

export default {
    initApi: async (token: string) => (await import('../utils/api/ApiProvider')).initApiProvider(token),
    loadConfig: async function (loginStatus: LoginResult | null): Promise<TactinConfigData> {
        const clientConfig = await api().Admin.getClientConfigData();
        if (!clientConfig.translation) {
            if (loginStatus) {
                const translations = await api().Language.getTranslations(loginStatus.languageId);
                clientConfig.translation = translations.reduce((set, t) => {
                    set[t.original] = t.translated;
                    return set;
                }, {} as { [key: string]: string });
            } else
                clientConfig.translation = {};
        }
        const standardHandlers = await api().UserInterface.getHandlerConfig();

        await UIFactory.Init();

        return { clientConfig, standardHandlers: (standardHandlers || new Map<string, string>()) };
    }
}
