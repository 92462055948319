import { TSystemCommandApi } from "./ApiTypes";
import * as Api from "./TactinApi";

const MODULE = "SystemCommandModule";

export const SystemCommandApi = (token: string): TSystemCommandApi => ({
    callSystemCommandById: (id: number) =>
        Api.sendRequest(MODULE, "callSystemCommandById", { id }, token),
    callSystemCommandByClass: (systemCommandClass: string, systemCommandConfig: string) =>
        Api.sendRequest(MODULE, "callSystemCommandByClassAndConfig", {
            class: systemCommandClass,
            config: systemCommandConfig
        }, token),
    callContexedSystemCommandById: (id: number, context: any) =>
        Api.sendRequest(MODULE, "callContexedSystemCommandById", {
            id,
            actionContext: context
        }, token),
    callContexedSystemCommandByClass: (systemCommandClass: string, systemCommandConfig: string, context: any) =>
        Api.sendRequest(MODULE, "callContexedSystemCommandByClassAndConfig", {
            class: systemCommandClass,
            config: systemCommandConfig,
            actionContext: context
        }, token)
});
