import React from "react";
import { PropertyType } from "../../model/Property";
import { tactin } from "../../utils/TactinGlobals";
import Card from "../cards/Card";
import { CardSubPanelWrapper } from "../cards/CardSubPanelWrapper";
import { PopupUI } from "../core/UIFactory";
import { BitControl } from "../propertycontrols/BitControl";
import { DateControl } from "../propertycontrols/DateControl";
import { DecimalControl } from "../propertycontrols/DecimalControl";
import { IntegerControl } from "../propertycontrols/IntegerControl";
import { ItemToItemControl } from "../propertycontrols/ItemToItemControl";
import { ItemToManyItemControl } from "../propertycontrols/ItemToManyItemControl";
import { LinkControl } from "../propertycontrols/LinkControl";
import { RichTextControl } from "../propertycontrols/RichTextControl/RichTextControl";
import { TextControl } from "../propertycontrols/TextControl";
import { UserControl } from "../propertycontrols/UserControl";
import { ValueListControl } from "../propertycontrols/ValueListControl";
import './CustomFormUI.css'

export function CustomFormUI(configuration: any): PopupUI {
    const tr = tactin().configuration.translate;

    return {
        title: tr('Custom UI'),
        buttons: [[tr('Anuluj'), ''],[tr('Zmień'),'ok']],
        control: props => <CustomForm configuration={configuration} {...props} />,
        onClose: async (signal, state) => true
    }
}

type Props = {
    state: any;
    setState: (ns: any) => void;
    configuration: any;
    processing?: boolean;
}

export default function CustomForm(props: Props) {

    const change = (field: string, value: any, showAs?: string) =>
        props.setState({...(props.state || {}), [field]: { value, showAs }});


    const fields: FieldConfig[] = props.configuration?.fields ?? [];

    return <div className='custom-field-panel'>
        <CardSubPanelWrapper configuration={props.configuration} hideEmptyToolbar={true} >
            {fields.map((f, i) =>
                <Field key={i}
                    name={f.name || f.field}
                    type={f.type}
                    config={f.config}
                    value={props.state[f.field] ?? f.default ?? null}
                    onChange={(val, showAs) => change(f.field, val, showAs)}
                    readonly={props.processing ?? false} />)}
        </CardSubPanelWrapper>
    </div>
}

type FieldConfig = {
    field: string;
    name: string;
    type: PropertyType;
    config?: any;
    default?: any;
};

type FieldProps = {
    name: string;
    type: PropertyType;
    value: { value: any, showAs?: string } | null;
    onChange: (v: any, showAs?: string) => void;
    config?: any;
    readonly?: boolean
}

function Field({ name, type, value, onChange, config, readonly }: FieldProps) {

    const LocalControl = getPropertyControl(type);
    if (!LocalControl)
        return null;
    return <LocalControl label={name || ''}
        value={value?.value || null}
        showAs={value?.showAs}
        onChange={onChange}
        configuration={config}
        readOnly={readonly} />
}

function getPropertyControl(type: PropertyType = 'TEXT') {
    switch (type) {
        case 'BIT': return BitControl;
        case 'DATE': return DateControl;
        case 'DECIMAL': return DecimalControl;
        case 'FILELINK': return LinkControl;
        case 'IMAGE': return LinkControl;
        case 'INTEGER': return IntegerControl;
        case 'ITEM2ITEM': return ItemToItemControl;
        case 'ITEM2MANYITEMS': return ItemToManyItemControl;
        case 'RICHTEXT': return RichTextControl;
        case 'TEXT': return TextControl;
        case 'USER': return UserControl;
        case 'VALUELIST': return ValueListControl;
    }
    return null;
}
