import dateFormat from "dateformat";
import { tactin } from "./TactinGlobals";

function getISOWeek(date: Date) {
    const woy = Math.floor((10 + dayOfYear(date) - (date.getDay() || 7)) / 7);
    if(woy < 1)
        return numberOfWeeks(date.getFullYear() - 1);
    else if(woy > numberOfWeeks(date.getFullYear()))
        return 1;
    else
        return woy;
}

function dayOfYear(date: Date): number {
    const offsets = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
    return  offsets[date.getMonth()] + date.getDate() + ((isLeapYear(date) && date.getMonth() > 1) ? 1 : 0);
}

function isLeapYear(date: Date): boolean {
    const year = date.getFullYear();
    return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
}

function numberOfWeeks(year: number): number {
    const p = (y: number) => (y + Math.floor(y / 4) - Math.floor(y / 100) + Math.floor(y / 400)) % 7;
    return 52 + ((p(year) === 4 || p(year - 1) === 3) ? 1 : 0);
}

export const weekDayNames = ['Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'Sb', 'Nd']
export const monthNames = ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'];
function pad0(num: number, lenght: number = 2) {
    const result = String(num);
    return '0'.repeat(Math.max(0, lenght - result.length)) + result;
}
function toJSONDate(date: Date): string {
    return `${date.getFullYear()}-${pad0(date.getMonth() + 1)}-${pad0(date.getDate())}` +
        ` ${pad0(date.getHours())}:${pad0(date.getMinutes())}`;
}

function fromJSONDate(date: string): Date | null {
    const match = date.match(/^(\d+)-(\d+)-(\d+) (\d+):(\d+)$/)
    if(!match)
        return null;
    const d = match.slice(1).map(s => Number(s));
    return new Date(d[0], d[1]-1, d[2], d[3], d[4]);
}

function formatDate(date: number | Date, format?: string) {
    const cfgFormat = format || tactin().configuration.systemInfo('defaultDateFormat') || 'yyyy-MM-dd';
    const fixedFormat = cfgFormat.replaceAll('M', '!!').replaceAll('m', 'M').replaceAll('!!', 'm');
    return dateFormat(date, fixedFormat);
}

export const DateUtils = {
    getISOWeek,
    toJSONDate,
    fromJSONDate,
    formatDate
}
