import React, { useEffect, useState } from 'react';
import { ClientItem } from '../../model/item/ItemTypes';
import Card from './Card';

type CardManagerProps = {
    cards: CardStackEntry[];
}

export default function CardManager(props: CardManagerProps) {
    const [selected, setSelected] = useState(() => props.cards.length - 1);

    useEffect(() => {
        setSelected(props.cards.length - 1);
    }, [props.cards.length]);

    if (!props.cards.length)
        return null;
    return <div className='card-manager'>
        <div className='card-menu'>
            {props.cards.map((e, i) =>
                <div key={i}
                    className={`card-menu-position ${i === selected ? 'selected' : ''}`}
                    onClick={() => setSelected(i)} >
                    {e.item.item.showAs || `Item ${e.cardId || e.item.item.itemID || '---'}`}
                </div>)}
        </div>
        <div className='card-container'>
            {props.cards.map((e, i) =>
                <Card
                    key={`${e.item.item.itemID}-${e.item.item.version || 0}`}
                    item={e.item} isTemplate={e.isTemplate} cardId={e.cardId}
                    hidden={i !== selected} />)}
        </div>
    </div>;
}


type CardStackEntry = {
    item: ClientItem;
    isTemplate?: boolean;
    cardId?: string;
}

export function useCardManager() {
    const [cards, setCards] = useState<CardStackEntry[]>([]);

    const removeCard = (prev: CardStackEntry[], cardId?: string, itemId?: number) => {
        if (cardId)
            return prev.filter(cardIdFilter(cardId));
        else if (itemId)
            return prev.filter(itemIdFilter(itemId));
        else if (cards.length)
            return prev.slice(0, cards.length - 1);
        else
            return prev;
    }

    const openCard = (item: ClientItem, isTemplate?: boolean, cardId?: string) => {
        if (item)
            setCards(prev => [...prev, { item, isTemplate, cardId }]);
    }

    const closeCard = (cardId?: string, itemId?: number) => {
        setCards(prev => removeCard(prev, cardId, itemId));
    }

    const replaceCard = ([closedCardId, itemId]: [string?, number?], [item, isTemplate, openedCardId]: [ClientItem, boolean?, string?]) => {
        setCards(prev => [...removeCard(prev, closedCardId, itemId), { item, isTemplate, openedCardId }]);
    }

    return { cards, openCard, closeCard, replaceCard }
}

function cardIdFilter(cardId: string) {
    return (e: CardStackEntry) => e.cardId !== cardId;
}
function itemIdFilter(itemId: number) {
    return (e: CardStackEntry) => e.item.item.itemID !== itemId;
}
