import { HandleErrors } from "../../utils/api/ApiErrorHandler";
import { api } from "../../utils/api/ApiProvider";
import { ShowAdditionalConfigurationResult, toShowAdditionalConfigurationResult } from "../../utils/api/Results";
import { Failed, ServerActionResult } from "../../utils/api/TactinApi";
import { tactin } from "../../utils/TactinGlobals";
import { DownloadFileEvent, LogoutEvent, ShowCustomUI, ShowMessageEvent } from "../events/TactinEvents";
import { ActionContextType, ActionContextProvider, ListActionContext } from "./ActionContext";

export function LogoutSystemAction() {
    return async () => {
        await api().User.logout();
        tactin()?.eventBus.notify(new LogoutEvent());
        return null;
    }
}

export function ExportToExcelSystemAction(contextProvider?: ActionContextProvider) {
    return async () => {
        if (!contextProvider)
            throw new Failed('Bład konfiguracji: Brak dostawcy kontekstu!');
        const listContext = contextProvider('list');
        if (!listContext || !(listContext instanceof ListActionContext))
            throw new Failed('Błąd konfiguracji: Dostawca kontekstu nie obsługuje kontekstu listy!');
        const listData = listContext.data;

        const fileToken = await api().FileRepository.excelFileDownload({
            dataProviderId: String(listContext.id),
            ...listData
        });
        tactin()?.eventBus.notify(new DownloadFileEvent(fileToken));
        return null;
    }
}

export function ChangeUserPasswordSystemAction() {
    return async () => {
        tactin()?.eventBus.notify(new ShowCustomUI('ChangePassword', {}));
        return null;
    }
}

function CSCSA(configMap: any, contextProvider?: ActionContextProvider) {
    const systemCommandId = Number(configMap.systemCommandId ?? -1);
    const systemCommandClass = configMap.systemCommandClass ?? '';
    const systemCommandConfig = configMap.systemCommandConfig ?? '';
    const requestedContextType: ActionContextType = configMap.context ?? 'none';

    const onSuccess = (result: ServerActionResult) => {
        let additionalConfigResult: ShowAdditionalConfigurationResult | null = null;
        try {
            additionalConfigResult = toShowAdditionalConfigurationResult(result);
        } catch (e) { }
        if (additionalConfigResult) {
            const formConfig = { ...additionalConfigResult.formConfiguration };

            tactin().eventBus.notify(new ShowCustomUI(
                'CustomForm',
                formConfig,
                async (signal, state) => {
                    if (!signal)
                        return;

                    //put the changes from the panel to the resulting configuratio
                    let result = { ...(additionalConfigResult?.resultConfiguration ?? {}) };
                    result.systemCommandConfig = { ...(result.systemCommandConfig ?? {}), ...state }

                    if (!result.systemCommandClass)
                        return;

                    const cscsa = CSCSA(result);
                    const res = await cscsa();

                    if (res.message)
                        tactin().eventBus.notify(new ShowMessageEvent(res.message));
                }));
        } else {
            if (result.message)
                tactin().eventBus.notify(new ShowMessageEvent(result.message));
        }
        return result;
    }

    return async () => {
        if (contextProvider && requestedContextType !== 'none') {
            const context = contextProvider(requestedContextType);
            if (!context)
                throw new Failed('Błędny kontekst dla akcji.');
            else {
                if (systemCommandId > 0)
                    return api().SystemCommand.callContexedSystemCommandById(systemCommandId, context).then(onSuccess);
                else if (systemCommandClass)
                    return api().SystemCommand.callContexedSystemCommandByClass(systemCommandClass, systemCommandConfig, context).then(onSuccess);
                else
                    throw new Failed('No command ID nor command class specified. Don\'t know what to do.');
            }
        } else {
            if (systemCommandId > 0)
                return api().SystemCommand.callSystemCommandById(systemCommandId).then(onSuccess);
            else if (systemCommandClass)
                return api().SystemCommand.callSystemCommandByClass(systemCommandClass, systemCommandConfig).then(onSuccess);
            else
                throw new Failed('No command ID nor command class specified. Don\'t know what to do.');
        }
    }
}

export function CallSystemCommandSystemAction(configMap: any, contextProvider?: ActionContextProvider) {
    const func = CSCSA(configMap, contextProvider);
    return async () => {
        await func();
        return null;
    }
}
