import React from 'react';
import { TLocalEventBus } from '../../model/events/LocalEventBus';
import { ShowCustomUI } from '../../model/events/TactinEvents';
import { Option } from '../../model/Interface';
import { ColumnFilter, newFilterManager } from '../../model/list/ColumnFilter';
import { tactin } from '../../utils/TactinGlobals';
import { ChangePasswordUI } from '../cards/useraddon/ChangePasswordPopup';
import { CustomFormUI } from '../plugins/CustomFormUI';
import { getPluginUI } from '../plugins/PluginUI';

export default {
    Init,
    getViewerPanel,
    getStandardOptionList,
    getEntitySelectorPanel,
    getPopupUI
}

const Components = {
    MainPanel: null as any,
    StandardTabArea: null as any,
    GroupListViewer: null as any,
    TreeListViewer: null as any,
    ReportPanel: null as any,
    ItemReportPanel: null as any,
    CalendarPanel: null as any,
    ImportPanel: null as any,
    ChartPanel: null as any,

    ConfigPropertyPanel: null as any,
    ConfigTextTemplatePanel: null as any,
    ConfigReportPanel: null as any,
    ConfigItemTypePanel: null as any,
    ConfigFileRepositoryPanel: null as any,
    ConfigLanguagePanel: null as any,
    ConfigDataProviderPanel: null as any,
    ConfigPermissionPanel: null as any,
    ConfigCategoryPanel: null as any,
    ConfigSystemInfoPanel: null as any,
    ConfigMainMenuPanel: null as any,
    ConfigMainTabsPanel: null as any,
    ConfigCardsPanel: null as any,
    ConfigToolbarPanel: null as any,
    ConfigDefaultsPanel: null as any,
    ConfigInterfacePanel: null as any,
    ConfigTabPanelUser: null as any,

    FilterBox: null as any,
}

async function Init() {
    Components.MainPanel = (await import('../panels/MainPanel')).MainPanel;
    Components.StandardTabArea = (await import('../panels/StandardTabArea')).StandardTabArea;
    Components.GroupListViewer = (await import('../list/GroupListViewer')).default;
    Components.TreeListViewer = (await import('../list/TreeListViewer')).default;
    Components.ReportPanel = (await import('../panels/ReportPanel')).ReportPanel;
    Components.ItemReportPanel = (await import('../panels/ReportPanel')).ItemReportPanel;
    Components.CalendarPanel = (await import('../panels/CalendarPanel')).default;
    Components.ImportPanel = (await import('../panels/ImportPanel')).default;
    Components.ChartPanel = (await import('../panels/ChartPanel')).default;

    Components.ConfigPropertyPanel = (await import('../configs/PropertyPanel')).default;
    Components.ConfigTextTemplatePanel = (await import('../configs/TextTemplatePanel')).default;
    Components.ConfigReportPanel = (await import('../configs/ReportPanel')).default;
    Components.ConfigItemTypePanel = (await import('../configs/ItemTypePanel')).default;
    Components.ConfigFileRepositoryPanel = (await import('../configs/FileRepositoryPanel')).default;
    Components.ConfigLanguagePanel = (await import('../configs/Translations/LanguagePanel')).default;
    Components.ConfigDataProviderPanel = (await import('../configs/DataProviders/DataProviderPanel')).default;
    Components.ConfigPermissionPanel = (await import('../configs/PermissionGroups/PermissionPanel')).default;
    Components.ConfigCategoryPanel = (await import('../configs/Category/CategoryPanel')).default;
    Components.ConfigSystemInfoPanel = (await import('../configs/SystemInfoPanel')).default;
    Components.ConfigMainMenuPanel = (await import('../configs/Interface/MainMenuPanel')).default;
    Components.ConfigMainTabsPanel = (await import('../configs/Interface/MainTabsPanel')).default;
    Components.ConfigCardsPanel = (await import('../configs/Interface/CardsPanel')).default;
    Components.ConfigToolbarPanel = (await import('../configs/Interface/ToolbarPanel')).default;
    Components.ConfigDefaultsPanel = (await import('../configs/Interface/DefaultsPanel')).default;
    Components.ConfigInterfacePanel = (await import('../configs/Interface/InterfacePanel')).default;
    Components.ConfigTabPanelUser = (await import('../configs/ConfigTabPanel')).ConfigTabPanelUser;

    Components.FilterBox = (await import('../basic/FilterBox')).default;
}

export type ViewerPanel = {
    main: JSX.Element | null;
    additional?: JSX.Element;
}
function getViewerPanel(name: string, primaryConfig: object, standardConfig: object, parentID?: number): ViewerPanel {
    let config = { ...standardConfig, ...primaryConfig } as any;
    const toolbarUsagePlace = config?.toolbarUsagePlace as number || -1;

    switch (name) {
        case 'StandardTabArea': {
            const styleName = config.styleName as string || '';
            const usagePlace = config.menuUsagePlace === undefined ? -1 : config.menuUsagePlace as number;
            return {
                main: <Components.StandardTabArea
                    className={styleName}
                    usagePlace={usagePlace}
                    isCached={false} />
            };
        }
        case 'MainPanel': {
            const styleName = config.styleName as string || '';
            const usagePlace = config.menuUsagePlace === undefined ? -1 : config.menuUsagePlace as number;
            return {
                main: <Components.MainPanel
                    direction='WEST'
                    className={styleName}
                    usagePlace={usagePlace} />
            };
        }
        // case "SmartPanel":
        //     return new CustomTabPanel(uiTabPanelTypes.NORTH,
        //             config.getString("backgroundStyle"),
        //             config.getString("tabButtonStyle"),
        //             config.getString("tabScrollButtonsStyle"),
        //             config.getString("tabHolderStyle"),
        //             config.getString("subTabHolderStyle"),
        //             config.getString("separatorStyle"),
        //             config.getInteger("menuUsagePlace"),
        //             null,
        //             true);
        case "FileImporterPanel":
            return {
                main: <Components.ImportPanel
                    importerName={config?.importerName ?? ''}
                    allowOne={config?.allowOne ?? false} />
            }
        case 'ListDataViewer':
        case 'TreeListDataViewer':
            const viewOnly = config?.viewOnly || false;
            const wildcards = config?.wildcards as string[] || [];
            const toolbarConfig = config?.toolbar as string[] || [];
            const showDescription = config?.showDescription as boolean || false;
            let main = null;
            const filterManager = newFilterManager();
            if (name === 'TreeListDataViewer')
                main = <Components.TreeListViewer
                    dataProvider={config?.dataProvider as string || ''}
                    parentColumn={config?.parentColumn as string || ''}
                    rootId={config?.rootId as number || 0}
                    wildcards={wildcards}
                    toolbarConfiguration={toolbarConfig}
                    decorate
                    toolbarUsagePlace={toolbarUsagePlace}
                    filterDataProvider={config?.filterDataProvider as string || ''}
                    openOnDblClick={!viewOnly}
                    showDescription={showDescription} />
            else
                main = <Components.GroupListViewer
                    dataProvider={config?.dataProvider as string || ''}
                    wildcards={wildcards}
                    filters={filterManager}
                    toolbarConfiguration={toolbarConfig}
                    decorate
                    blockDynamicGrouping={config?.blockGrouping as boolean || false}
                    toolbarUsagePlace={toolbarUsagePlace}
                    openOnDblClick={!viewOnly}
                    showDescription={showDescription} />
            const additional = <Components.FilterBox filterManager={filterManager} />
            return { main, additional };
        case "CalendarDataViewer":
            return {
                main: <Components.CalendarPanel
                    dataProviderId={config?.dataProvider || ''}
                    dateColumnName={config?.dateColumn || ''}
                    showAsColumn={config?.showAsColumn || ''} />
            };
        case "ChartDataViewer":
            let chartOptions, chartData;
            try {
                chartOptions = JSON.parse(config.chartOptions);
                chartData = JSON.parse(config.chartData);
            } catch (e) {
                return { main: <div>{tactin().configuration.translate('Chart config is not valid')}</div> }
            }
            return {
                main: <Components.ChartPanel
                    dataProvider={config.dataProvider}
                    toolbarUsagePlace={toolbarUsagePlace}
                    indexLimit={config.indexLimit}
                    includeOthers={config.includeOthers}
                    sortBySerie={config.sortBySerie}
                    chartType={config.chartType}
                    chartOptions={chartOptions}
                    chartData={chartData} />
            }

        // case "DatabaseUpdatePanel":
        //     return new DatabaseUpdatePanel();
        // case "CacheReloadPanel":
        //     return new CacheReloadPanel();
        case 'ReportViewerPanel':
            if (config.reportID)
                return { main: <Components.ItemReportPanel reportID={config.reportID} /> }
            else
                return {
                    main: <Components.ReportPanel
                        reportDefinitionID={config.reportDefinitionID}
                        dataProvider={config.dataProvider}
                        start={config.start}
                        length={config.length}
                        sortColumn={config.sortColumn}
                        sortDirection={config.sortDirection}
                        name={config.name}
                        number={config.number}
                        parentID={parentID} />
                }

        case 'PropertyPanel':
            return { main: <Components.ConfigPropertyPanel /> };
        case 'TextTemplatePanel':
            return { main: <Components.ConfigTextTemplatePanel /> };
        case 'ReportPanel':
            return { main: <Components.ConfigReportPanel /> };
        case 'ItemTypePanel':
            return { main: <Components.ConfigItemTypePanel /> };
        case 'FileLinkPanel':
            return { main: <Components.ConfigFileRepositoryPanel /> };
        case 'LanguagePanel':
            return { main: <Components.ConfigLanguagePanel /> };
        case 'DataProviderPanel':
            return { main: <Components.ConfigDataProviderPanel /> };
        case 'PermissionPanel':
            return { main: <Components.ConfigPermissionPanel /> };
        case 'CategoryPanel':
            return { main: <Components.ConfigCategoryPanel /> };
        case 'SystemInfoPanel':
            return { main: <Components.ConfigSystemInfoPanel /> };
        case 'TactinMainMenuPanel':
            return { main: <Components.ConfigMainMenuPanel /> };
        case 'MainTabsPanel':
            return { main: <Components.ConfigMainTabsPanel /> };
        case 'CardsPanel':
            return { main: <Components.ConfigCardsPanel /> };
        case 'ToolbarPanel':
            return { main: <Components.ConfigToolbarPanel /> };
        case 'DefaultsPanel':
            return { main: <Components.ConfigDefaultsPanel /> };
        case 'InterfacePanel':
            return { main: <Components.ConfigInterfacePanel /> };
        case 'EmbeddedReactPanel':
            return { main: <Components.ConfigTabPanelUser /> };
        default:
            return { main: null };
    }
}

function getStandardOptionList(config: (string | [string, any])[]): Option[] {
    const result: Option[] = [];
    for (let i = 0; i < config.length; i++) {
        const cfg = config[i];
        let option: Option | null;
        if (typeof cfg === 'string')
            option = getStandardOption(cfg);
        else
            option = getStandardOption(...cfg)
        if (option) {
            option.order = i;
            result.push(option);
        }
    }
    return result;
}

function getStandardOption(option: string, configuration?: any): Option | null {
    switch (option) {
        case 'excelExport':
            return {
                id: 0,
                name: 'Eksportuj do Excel',
                icon: 'excel-file',
                description: '',
                handlerClass: 'toolbarButton',
                handlerConfiguration: {
                    actionClass: 'ExportToExcelSystemAction',
                    actionConfig: configuration
                },
                order: 0,
                permissions: [],
                usagePlace: -1
            };
        case 'createInstanceStandalone':
        case 'createInstanceLinked':
            return {
                name: `Utwórz instancję z szablonu (${option.endsWith('Standalone') ? 'oderwana' : 'powiązana'})`,
                icon: '',
                handlerClass: 'toolbarButton',
                handlerConfiguration: {
                    actionClass: 'CreateItemFromTemplateSystemAction',
                    actionConfig: {
                        type: option.endsWith('Standalone') ? 'deep' : 'shallow',
                        ...(configuration || {})
                    }
                },
                id: 0, description: '', order: 0, permissions: [], usagePlace: -1
            };
        case 'individualizeItem':
            return {
                name: 'Usamodzielnij',
                icon: '',
                handlerClass: 'toolbarButton',
                handlerConfiguration: {
                    actionClass: 'IndividualizeItemSystemAction',
                    actionConfig: configuration
                },
                id: 0, description: '', order: 0, permissions: [], usagePlace: -1
            };
        case 'restoreSharedItem':
            return {
                name: 'Przywróć',
                icon: '',
                handlerClass: 'toolbarButton',
                handlerConfiguration: {
                    actionClass: 'RestoreSharedItemSystemAction',
                    actionConfig: configuration
                },
                id: 0, description: '', order: 0, permissions: [], usagePlace: -1
            };
        case 'createSeries':
            return {
                name: 'Utwórz serię',
                icon: '',
                handlerClass: 'toolbarButton',
                handlerConfiguration: {
                    actionClass: 'CreateSeriesSystemAction',
                    actionConfig: configuration
                },
                id: 0, description: '', order: 0, permissions: [], usagePlace: -1
            };
        case 'restoreSeries':
            return {
                name: 'Przywróć serię',
                icon: '',
                handlerClass: 'toolbarButton',
                handlerConfiguration: {
                    actionClass: 'RestoreSeriesSystemAction',
                    actionConfig: configuration
                },
                id: 0, description: '', order: 0, permissions: [], usagePlace: -1
            };
        case 'restoreDeletedItem':
                return {
                    name: 'Przywróć item',
                    icon: '',
                    handlerClass: 'toolbarButton',
                    handlerConfiguration: {
                        actionClass: 'RestoreDeletedItemAction',
                        actionConfig: configuration
                    },
                    id: 0, description: '', order: 0, permissions: [], usagePlace: -1
                };
        // case "omniFilter":
        //     cfg = new ClientJSONObject();
        //     cfg.setString("filterType", "textFilter");

        //     TTJSONObject filterCfg = new ClientJSONObject();
        //     filterCfg.setString("columnAlias", "quickfilter_concatenation");
        //     filterCfg.setString("showAs", "OmniFilter");
        //     cfg.setObject("filter", filterCfg);

        //     return new Option(
        //             "OmniFilter",
        //             "",
        //             "toolbarFilter",
        //             cfg);

        default:
            return null;
    }
}

export type EntitySelector = {
    control: (filters: ColumnFilter[]) => JSX.Element;
    allowFiltering: boolean;
};
function getEntitySelectorPanel(config: any, onSelect: (id: number, showAs: string, highlight: boolean) => void, eventBus?: TLocalEventBus): EntitySelector | null {
    const handlerClass = config.handlerClass as string || '';
    const dataProvider = config.dataProvider as string || '';
    const wildcards = config.wildcards as string[] || [];
    const parentColumn = config.parentColumn as string || '';
    const rootId = config.rootId as number || 0;

    switch (handlerClass) {
        case "ListDataViewer":
            return {
                control: filters => <Components.GroupListViewer
                    dataProvider={dataProvider}
                    wildcards={wildcards}
                    filters={filters}
                    toolbarConfiguration={[]}
                    decorate={false} blockDynamicGrouping={false}
                    toolbarUsagePlace={-1} openOnDblClick={false}
                    onSelect={onSelect}
                    eventBus={eventBus} />,
                allowFiltering: true
            }
        case "TreeListDataViewer":
            return {
                control: filters => <Components.TreeListViewer
                    dataProvider={dataProvider}
                    parentColumn={parentColumn} rootId={rootId}
                    wildcards={wildcards}
                    decorate={false} toolbarConfiguration={[]} toolbarUsagePlace={-1}
                    filterDataProvider={''} openOnDblClick={false}
                    onSelect={onSelect}
                    eventBus={eventBus} />,
                allowFiltering: false
            }
    }
    return null;
}

export type PopupUI = {
    title: string;
    control: (props: { state: any, setState: (ns: any) => void, processing?: boolean }) => JSX.Element;
    buttons: [string, string][];
    onClose: (signal: string, state: any) => Promise<boolean>;
}
async function getPopupUI(event: ShowCustomUI): Promise<PopupUI | null> {
    switch (event.uiName) {
        case 'ChangePassword':
            return ChangePasswordUI(event.configuration.itemId);
        case 'CustomForm':
            return CustomFormUI(event.configuration);
    }
    if (event.uiName.includes('.'))
        return getPluginUI(event.uiName, event.configuration);
    return null;
}
