import React, { useState } from 'react';
import { HandleErrors } from '../../utils/api/ApiErrorHandler';
import { tactin } from '../../utils/TactinGlobals';

type Props = {
    onClick: (action: ToolbarOption) => Promise<void>;
}

export type ToolbarOption = 'save' | 'saveAndClose' | 'delete' | 'saveAsNew';

export default function ItemToolbar(props: Props) {
    const [enabled, setEnabled] = useState(true);

    const click = (action: ToolbarOption) => {
        if (enabled) {
            setEnabled(false);
            props.onClick(action).catch(HandleErrors()).finally(() => setEnabled(true));
        }
    }

    return <div className='toolbar'>
        <Button label='Zapisz i zamknij' action='saveAndClose' onClick={click} />
        <Button label='Zapisz jako nowy' action='saveAsNew' onClick={click} />
        <Button label='Zapisz' action='save' onClick={click} />
        <Button label='Usuń' action='delete' onClick={click} />
    </div>
}

type ButtonProps = {
    label: string;
    action: ToolbarOption;
    onClick: (action: ToolbarOption) => void;
}

function Button({ label, action, onClick }: ButtonProps) {
    let iconName = '';
    switch (action) {
            
        case 'saveAndClose':
            iconName = "saveclose_wh";
            break;
        case 'delete':
            iconName = "trash_wh";
            break;
        case 'saveAsNew':
        case 'save':
            iconName = "save_wh";
            break;
    }

    return <div className='toolbarButton'
        onClick={_ => onClick(action)}>
        <img className='image' src={'img/' + iconName + '.png'} />
        <span className='label'>{tactin().configuration.translate(label)}</span>
    </div>
}
