import { Option, UsagePlace, UsagePlaceConverter } from '../../model/Interface';
import { TUserInterfaceApi } from './ApiTypes';
import { toGetOptionsResult, toSerializableObjectArrayResult, toStringMapResult } from './Results';
import * as Api from './TactinApi';

const MODULE = 'UserInterfaceModule';

const toUsagePlaceArray = (result: Api.ServerActionResult): Promise<UsagePlace[]> =>
    toSerializableObjectArrayResult<UsagePlace>(result, 'UsagePlace');

export const UserInterfaceApi = (token: string): TUserInterfaceApi => ({
    getHandlerConfig: () =>
        Api.sendRequest(MODULE, 'getHandlerConfig', {}, token).then(toStringMapResult),
    saveHandlerConfig: (handlerClass: string, config: string) =>
        Api.sendRequest(MODULE, 'saveHandlerConfig', { handlerClass, config }, token).then(toStringMapResult),
    getUsagePlaces: () =>
        Api.sendRequest(MODULE, 'getUsagePlaces', {}, token).then(toUsagePlaceArray),
    saveUsagePlace: (entity: UsagePlace, original?: UsagePlace) =>
        Api.sendRequest(MODULE, 'saveUsagePlace', { entity: UsagePlaceConverter.toApi(entity, original) }, token).then(toUsagePlaceArray),
    removeUsagePlace: (id: number) =>
        Api.sendRequest(MODULE, 'removeUsagePlace', { id }, token).then(toUsagePlaceArray),
    getOptions: (usagePlace: number) =>
        Api.sendRequest(MODULE, 'getOptions', { usagePlace }, token).then(toGetOptionsResult),
    getAllOptions: () =>
        Api.sendRequest(MODULE, 'getAllOptions', {}, token).then(toGetOptionsResult),
    saveOption: (entity: Option) =>
        Api.sendRequest(MODULE, 'saveMenuOption', { entity }, token).then(toGetOptionsResult),
    removeOption: (id: number) =>
        Api.sendRequest(MODULE, 'removeMenuOption', { id }, token).then(toGetOptionsResult),
    reorderOptions: (ids: number[]) =>
        Api.sendRequest(MODULE, 'reorderMenuOptions', { ids }, token).then(toGetOptionsResult),
    addPermission: (optionId: number, permissionId: number) =>
        Api.sendRequest(MODULE, 'addPermissionGroup', { mopId: optionId, prgId: permissionId }, token).then(toGetOptionsResult),
    removePermission: (optionId: number, permissionId: number) =>
        Api.sendRequest(MODULE, 'removePermissionGroup', { mopId: optionId, prgId: permissionId }, token).then(toGetOptionsResult),
});
