import React, { useEffect, useState } from 'react';
import { DownloadFileEvent, TactinEvent } from '../../model/events/TactinEvents';
import { HandleErrors } from '../../utils/api/ApiErrorHandler';
import { api } from '../../utils/api/ApiProvider';
import { downloadFileURL } from '../../utils/api/FileRepositoryApi';
import { tactin } from '../../utils/TactinGlobals';

export default function FileDownloader() {
    const [downloadURL, setDownloadURL] = useState('');

    useEffect(() => {
        const registration = tactin()?.eventBus.register(handler);
        return () => registration();
    });

    const handler = (event: TactinEvent) => {
        if (event instanceof DownloadFileEvent) {
            if (event.id > 0)
                api().FileRepository.getFile(event.id)
                    .then(token => downloadFile(token))
                    .catch(HandleErrors());
            else if (event.token)
                downloadFile(event.token);
        }
    }

    const downloadFile = (token: string) => {
        const url = downloadFileURL(token);
        setDownloadURL(url);
    }

    return <iframe style={{ display: 'none' }} src={downloadURL}></iframe>;
}
