import React from 'react';
import { BareItemToItemControl } from './ItemToItemControl';
import { WithLabel } from './LabeledControl';
import { BarePropertyControlProps } from './PropertyControl'

export function BareUserControl(props: BarePropertyControlProps) {
    const config = { handlerClass: 'ListDataViewer', dataProvider: 'SSDP_USERS' }

    return <BareItemToItemControl {...props} configuration={config} />
}

export const UserControl = WithLabel(BareUserControl);
