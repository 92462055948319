import React from 'react';
import { DateControl } from '../../propertycontrols/DateControl';
import './SeriesModifiedDate.css';

const notifMore28 = 'Wybrano dzień miesiąca większy niż 28. Jeśli nie będzie takiego dnia w miesiącu zostanie wybrany ostatni dzień danego miesiąca.';
const modeValues = ['interwałowy', 'wyliczony'];
const intervalValues = ['day', 'week', 'month', 'year'];
const intervalTexts = ['dzień', 'tydzień', 'miesiąc', 'rok'];
const daysName = ['poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota', 'niedziela'];
const order = ['pierwszy', 'drugi', 'trzeci', 'czwarty', 'ostatni'];
const monthsName = ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'];

type ModeType = 'interval' | 'calculated';
type TModState = {
    interval?: {
        type?: 'day' | 'week' | 'month' | 'year';
        subtype?: string;       //'after' | 'every' | 'selected';
        interval?: number;
        weekDays?: number[];    //0-monday,...,6-sunday
        weekDay?: number;       //0-monday,...,6-sunday
        weekNumbers?: number[]; //between 1 and 52
        dayOfMonth?: number;
        month?: number;         //0-jan,...,11-dec
        weekNumber?: number;    // 0...4
        sourceField?: string;
        defaultToday?: boolean;
    }
    baseValue?: string;         //daty in our JSON format (yyyy-MM-dd HH:mm)
    calculated?: {
        expression?: string;
        extended?: boolean;
    }
    type: ModeType;
}

type Options = {
    name: 'number' | 'checkbox' | 'list';
    number?: 'interval' | 'dayOfMonth';
    check?: 'weekDays' | 'weekNums';
    list?: 'weekDay' | 'month' | 'weekOrder';
    listBox?: boolean;
    checkboxBoxWeeks?: boolean;
    before?: string;
    after?: string;
    options?: string[];
    max?: number;
    className?: string;
}

function getYearWeeksNumber() {
    const year = [];
    for (let week = 1; week <= 52; week++)
        year.push(week.toString())
    return year
}

const dayMode: Options[][] = [
    [{ name: 'number', number: 'interval', before: 'Powtarzaj', after: 'dni po poprzedniej instancji' }],
    [{ name: 'number', number: 'interval', before: 'Co', after: 'y dzień' }],
    [{ name: 'checkbox', check: 'weekDays', before: 'Następujące dni tygodnia', options: daysName }],
]

const weekMode: Options[][] = [
    [{ name: 'number', before: 'Powtarzaj', after: 'tygodni po poprzedniej instancji' }],
    [{ name: 'number', before: 'Co' }, { name: 'list', list: 'weekDay', before: 'y tydzień na', options: daysName }],
    [
        { name: 'checkbox', before: 'Następujące tygodnie', check: 'weekNums', options: getYearWeeksNumber() },
        { name: 'list', list: 'weekDay', before: 'Dzień tygodnia', options: daysName, className: 'selectPosition' }
    ]
]

const monthMode: Options[][] = [
    [{ name: 'number', before: 'Powtarzaj', after: ' miesięcy po poprzedniej instancji' }],
    [
        { name: 'number', number: 'dayOfMonth', max: 31, before: 'W' },
        { name: 'number', before: 'y dzień co', after: 'miesiąc' }
    ],
    [
        { name: 'list', before: 'W', list: 'weekOrder', options: order },
        { name: 'list', list: 'weekDay', options: daysName },
        { name: 'number', before: 'co', after: 'u miesiąc' }
    ]
]

const yearMode: Options[][] = [
    [{ name: 'number', before: 'Powtarzaj', after: ' lat po poprzedniej instancji' }],
    [
        { name: 'number', number: 'dayOfMonth', max: 31, before: 'W' },
        { name: 'list', list: 'month', options: monthsName },
        { name: 'number', before: 'co', after: 'lat' }
    ],
    [
        { name: 'list', before: 'W', list: 'weekOrder', options: order },
        { name: 'list', options: daysName },
        { name: 'list', list: 'month', options: monthsName },
        { name: 'number', before: 'co', after: 'lat' }
    ]
]

const modes = {
    day: dayMode,
    week: weekMode,
    month: monthMode,
    year: yearMode,
}

type SeriesModDateProps = {
    value: TModState;
    onChange: (value: TModState) => void;
}

export default function SeriesModifiedDate({ value, onChange }: SeriesModDateProps) {

    const changeIntervalPart = (v: Partial<NonNullable<TModState['interval']>>) =>
        onChange({ ...value, interval: { ...value.interval, ...v } });

    const changeCalculatedPart = (v: Partial<NonNullable<TModState['calculated']>>) =>
        onChange({ ...value, calculated: { ...value.calculated, ...v } });

    const interaction = {
        interval: { get: value.interval?.interval, set: (v?: number) => changeIntervalPart({ interval: v }) },
        dayOfMonth: { get: value.interval?.dayOfMonth, set: (v?: number) => changeIntervalPart({ dayOfMonth: v }) },
        weekDays: { get: value.interval?.weekDays ?? [], set: (v: number[]) => changeIntervalPart({ weekDays: v }) },
        weekDay: { get: value.interval?.weekDay ?? 0, set: (v: number) => changeIntervalPart({ weekDay: v }) },
        month: { get: value.interval?.month ?? 0, set: (v: number) => changeIntervalPart({ month: v }) },
        weekOrder: { get: value.interval?.weekNumber ?? 0, set: (v: number) => changeIntervalPart({ weekNumber: v }) },
        weekNums: {
            get: (value.interval?.weekNumbers ?? []).map(i => i - 1),
            set: (v: number[]) => changeIntervalPart({ weekNumbers: v.map(i => i + 1) })
        },
    }

    const intValNum = () => {
        let val = 0;
        intervalValues.forEach((item, ind) => {
            if (item === value.interval?.type)
                val = ind;
        })
        return val;
    }

    const setIntValNum = (num: number) =>
        changeIntervalPart({ type: intervalValues.find((i, ind) => ind === num) as 'day' });

    const switchMode = (v: ModeType) => onChange({ ...value, type: v })

    function composer(ind: number) {
        return modes[value.interval?.type ?? 'day'][ind].map((opt, i) => {
            const key = i + ind;
            switch (opt.name) {
                case 'number':
                    return <NumberBox key={key} config={opt} max={opt.max}
                        value={interaction[opt.number ?? 'interval'].get}
                        onChange={(v) => interaction[opt.number ?? 'interval'].set(v)} />
                case 'checkbox':
                    return <CheckboxBox key={key} config={opt}
                        values={interaction[opt.check ?? 'weekDays'].get}
                        onChange={(v) => interaction[opt.check ?? 'weekDays'].set(v)} />
                case 'list':
                    return <ListBox key={key} label={opt.before}
                        options={opt.options ?? []}
                        className={`inlineSelect ${opt.className ?? ''}`}
                        value={interaction[opt.list ?? 'weekDay'].get}
                        onChange={v => interaction[opt.list ?? 'weekDay'].set(v)} />
            }
        })
    }

    function getIntervalComponent() {
        return <>{['after', 'every', 'selected'].map((mode, ind) =>
            <div key={mode} className='intervalMode' onFocus={() => {
                if (value.interval?.subtype !== mode) changeIntervalPart({ subtype: mode })
            }}>
                <RadioBox checked={value.interval?.subtype === mode} />
                {composer(ind)}
            </div>
        )}</>
    }

    return (
        <div>
            <TypeSwitch value={value.type} onChange={switchMode} />
            <div className='modeOptionsContainer'>
                {value.type === 'interval'
                    ?
                    <div>
                        <DateControl label='Data początkowa'
                            readOnly={false}
                            className='baseDateSelector'
                            configuration={{ format: 'yyyy-MM-dd' }}
                            value={value.baseValue ?? ''}
                            onChange={(v: string) => onChange({ ...value, baseValue: v })} />
                        <ListBox label='Interwał:'
                            options={intervalTexts}
                            value={intValNum()}
                            onChange={(v) => setIntValNum(v)}
                            className='intervalSelector' />
                        {getIntervalComponent()}
                    </div>
                    :
                    <Calculated expression={value.calculated?.expression ?? ''}
                        onChange={(v) => changeCalculatedPart({ expression: v })}
                        checked={value.calculated?.extended ?? false}
                        onSelect={(v) => changeCalculatedPart({ extended: v })} />}
            </div>
        </div>
    )
}

type CalculatedProps = {
    expression: string;
    onChange: (v: string) => void;
    checked: boolean;
    onSelect: (v: boolean) => void;
}

function Calculated({ expression, checked, onChange, onSelect }: CalculatedProps) {
    return <div className='advancedMode'>
        <span>Wartość</span>
        <input className='advancedInput' type='text' onChange={(e) => onChange(e.currentTarget.value)} value={expression} />
        <div className='intervalMode'>
            <label><input type='checkbox' checked={checked} onChange={(e) => onSelect(e.target.checked)} />
            Zaawansowane przetwarzanie wartości</label>
        </div>
    </div>
}

const RadioBox = ({ checked }: { checked: boolean }) => <input type='radio' name='modified' checked={checked} onChange={() => { }} />

type NumberBoxProps = {
    config: Options;
    value?: number;
    onChange: (v: number | undefined) => void;
    max?: number;
}

function NumberBox({ config, value, onChange, max }: NumberBoxProps) {
    function notification(v: number) {
        if (max && v > 28 && v < 32)
            alert(notifMore28);
    }

    const change = (str: string) => {
        const num = Number(str);
        if (str === '')
            onChange(undefined);
        else if (num > 0 && num <= (max ? max : Infinity))
            onChange(num);
    }

    return <>
        <span>{config.before}</span>
        <input type='number' min={1} onChange={(e) => change(e.currentTarget.value)}
            value={value ?? ''} onBlur={(e) => notification(+e.target.value)} className='inputBox' />
        <span>{config.after}</span>
    </>
}

type CheckboxBoxProps = {
    values: number[];
    config: Options;
    onChange: (v: number[]) => void;
}

function CheckboxBox({ values, config, onChange }: CheckboxBoxProps) {
    function checkboxChange(e: React.ChangeEvent<HTMLInputElement>) {
        let newValues = [...values];
        if (e.target.checked)
            newValues.push(Number(e.target.id));
        else
            newValues = newValues.filter((d) => d !== Number(e.target.id));
        onChange(newValues);
    }

    return <>
        <span>{config.before}</span>
        <div className='checkboxBox'>
            {config.options?.map((value, ind) => <label key={ind}>
                <input type='checkbox' id={ind.toString()} checked={values.includes(ind)} onChange={checkboxChange} />
                {value}
            </label>)}
        </div>
    </>
}

type ListBoxProps = {
    label: string | undefined;
    options: string[];
    value: number;
    onChange: (v: number) => void;
    className?: string;
}

function ListBox({ label = '', options, value, onChange, className = '', }: ListBoxProps) {
    return <div className={`selectMode ${className}`} >
        <label>{label}</label>
        <select value={value} onChange={(e) => onChange(+e.target.value)}>
            {options.map((i, ind) => {
                return <option key={ind} value={ind} >{i}</option>
            })}
        </select>
    </div>
}

type TypeSwitchProps = {
    value: ModeType;
    onChange: (v: ModeType) => void;
}
function TypeSwitch(props: TypeSwitchProps) {
    return <div className='selectMode' >
        <label>Tryb</label>
        <select value={props.value} onChange={(e) => props.onChange(e.target.value as ModeType)}>
            <option value='interval' >interwałowy</option>
            <option value='calculated' >wyliczony</option>
        </select>
    </div>
}
