import useInterfaceStore from './InterfaceStore';

export default function useRootStore(token: string) {
    const userInterface = useInterfaceStore(token);

    return {
        userInterface
    }
}

export type TRootStore = ReturnType<typeof useRootStore>;
