import React, { useEffect, useState } from 'react';
import { HandleErrors } from '../../utils/api/ApiErrorHandler';
import { api } from '../../utils/api/ApiProvider';
import { decodeShowAsDeleted } from '../../utils/DeletedItem';
import { EntityComboBox } from './EntityComboBox';
import { WithLabel } from './LabeledControl';
import { BarePropertyControlProps } from './PropertyControl'

export function BareItemToItemControl(props: BarePropertyControlProps) {
    const [showAs, setShowAs] = useState<string>('');

    useEffect(() => {
        if(props.value) {
            if (props.showAs === undefined) {
                api().Item.getItemShowAs(props.value)
                    .then(showas => {
                        const value = decodeShowAsDeleted(showas || '');
                        setShowAs(value)
                    })
                    .catch(e => HandleErrors())
            } else
                setShowAs(decodeShowAsDeleted(props.showAs))
        } else {
            if (props.showAs === '') 
                setShowAs(props.showAs);
        }
    }, [props.value, props.showAs]);

    return <EntityComboBox {...props} showAs={showAs} />
}

export const ItemToItemControl = WithLabel(BareItemToItemControl);
