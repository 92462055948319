import React from 'react';
import { PopupUI } from "../core/UIFactory";

type PluginCreator = (name: string, react: any) => PopupUI | null;

declare global {
    interface Window {
        registerPlugin?: (name: string, pluginCreator: PluginCreator) => void;
    }
}

const plugins = new Map<string, PluginCreator>();
function registerPlugin(name: string, pluginCreater: PluginCreator) {
    plugins.set(name, pluginCreater);
}
window.registerPlugin = registerPlugin;

async function loadPlugin(name: string) {
    if(!window.registerPlugin)
        throw new Error('Register function not initialized!');
    const file = await fetch(`/plugins/${name}.js`);
    const text = await file.text();
    Function('"use strict";'+text)();
    return true;
}

export async function getPluginUI(name: string, configuration: any): Promise<PopupUI | null> {
    const match = name.match(/^([^.]+)\.(.+)$/);
    if(!match)
        return null;
    const [,pluginName, uiName] = match;
    if(!plugins.has(pluginName))
        await loadPlugin(pluginName);
    const plugin = plugins.get(pluginName);
    if(plugin)
        return plugin(uiName, React);
    else
        return null;
}
