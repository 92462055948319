import React, { useState } from 'react';
import { WithLabel } from './LabeledControl';
import { BarePropertyControlProps, PropertyControlProps } from './PropertyControl'

export function BareIntegerControl({ value, readOnly, className = '', onChange }: BarePropertyControlProps) {
    const [startSymbol, changeStartSymbol] = useState('0');

    return (
        <div className={className}>
            <input
                type='text'
                value={value === 0 ? startSymbol : value}
                disabled={readOnly}
                onChange={(e) => {
                    let currentValue = e.target.value;
                    if (currentValue[0] === '-') {
                        const str = currentValue.slice(1, currentValue.length);
                        currentValue = currentValue[0] + str.replace(/[^\d]/g, '');
                    } else {
                        currentValue = currentValue.replace(/[^\d]/g, '');
                    }
                    if (Number(currentValue)) {
                        onChange(Number(currentValue));
                    } else {
                        // zero, empty, minus's rules
                        changeStartSymbol(currentValue);
                        onChange(0);
                    }
                }}
                onBlur={(e) => {
                    if (!Number(e.target.value)) {
                        changeStartSymbol('0');
                        onChange(0);
                    }
                }}
                onKeyDown={e => ['Escape', 'Enter'].includes(e.key) && e.currentTarget.blur()}
            />
        </div>
    );
}

export const IntegerControl = WithLabel(BareIntegerControl);
