import React, { useState } from 'react';
import PopupBox from '../../basic/PopupBox';
import { BareTextControl } from '../TextControl';
import { BareItemToItemControl } from '../ItemToItemControl';

type Props = {
    showPopup: (value: boolean) => void,
    insertLink: (fn: (showAs?: string) => string) => void
}

export default function HyperLinkPopup({ showPopup, insertLink }: Props) {
    const [valTextControl, setValTextControl] = useState('http://');
    const [valItem2ImtemControl, setValItem2ImtemControl] = useState(null);
    const [showAs, setShowAs] = useState('');
    const [checked, setChecked] = useState('1');

    return (
        <PopupBox
            title={<span>create link</span>}
            className={'some'}
            buttons={[
                (onClickCreator) => (<div key={2} className='button' onClick={onClickCreator('No')}>Anuluj</div>),
                (onClickCreator) => (<div key={1} className='button' onClick={onClickCreator('Yes')}>OK</div>)
            ]}
            onClose={(s?: string) => {
                if (s === 'Yes') {
                    let fn;
                    if (checked === '1') {
                        fn = (showAs?: string) => `<a href='${valTextControl}' target="_blank">${showAs || valTextControl}</a>`
                    } else {
                        fn = (showAs?: string) => `<a href='id:${valItem2ImtemControl}' target="_blank">${showAs || `id:${valItem2ImtemControl}`}</a>`
                    }
                    insertLink(fn);
                    showPopup(false);
                } else
                    showPopup(false);
            }} >
            <Mode label='Zewnętrzne hiperłacze' mode='1' selectedMode={checked} changeMode={(v) => setChecked(v)}>
                <BareTextControl value={valTextControl} className='withoutLabel'
                    configuration={{}}
                    onChange={(v) => setValTextControl(v)} />
            </Mode>
            <Mode label='Obiekt w systemie' mode='2' selectedMode={checked} changeMode={(v) => setChecked(v)}>
                <BareItemToItemControl value={valItem2ImtemControl} className='withoutLabel'
                    showAs={showAs}
                    configuration={{ handlerClass: 'ListDataViewer', dataProvider: 'SSDP_ALL_ITEMS' }}
                    onChange={(value, showAs = '') => {
                        setShowAs(showAs);
                        setValItem2ImtemControl(value);
                    }} />
            </Mode>
        </PopupBox >
    )
}

type ModeProps = {
    label: string;
    mode: string;
    selectedMode: string;
    changeMode: (v: string) => void;
    children: JSX.Element;
}

function Mode({ label, mode, selectedMode, changeMode, children }: ModeProps) {
    const onFocus = () => {
        if (selectedMode !== mode)
            changeMode(mode);
    }

    return <div className='imagePopupRadioButton' onFocus={onFocus}>
        <label>
            <input type='radio' name='chooser' checked={mode === selectedMode} onChange={() => { }} />
            {label}
        </label>
        {children}
    </div>
}
