import React, { useState } from 'react';
import { Option } from '../../model/Interface';
import { ActionContextProvider } from '../../model/systemactions/ActionContext';
import { HandleErrors } from '../../utils/api/ApiErrorHandler';
import { tactin } from '../../utils/TactinGlobals';
import { Notification, SystemActionExecutor, SystemActrion } from '../systemactions/SystemAction';

type ToolbarControlProps = {
    option: Option;
    contextProvider?: ActionContextProvider;
    afterAction?: (message: Notification | null) => void;
}
export default function ToolbarControl(props: ToolbarControlProps) {
    const standardConfig = tactin().standardHandler(props.option.handlerClass);
    const config = {
        ...standardConfig,
        ...props.option.handlerConfiguration
    }
    switch (props.option.handlerClass) {
        case "toolbarButton":
            return <ToolbarButton config={config} {...props} />
        case "toolbarLabel":
            return <ToolbarLabel option={props.option} config={config} />
        case "toolbarFilter":
            return <ToolbarFilter config={config} {...props} />
        default:
            return null;
    }
}

type ToolbarPositionProps = {
    config: any;
} & ToolbarControlProps;
function ToolbarButton(props: ToolbarPositionProps) {
    const [enabled, setEnabled] = useState(true);

    const icon = props.option.icon ? <img src={`img/${props.option.icon}.png`} /> : null;
    const text = <span>{tactin().configuration.translate(props.option.name)}</span>;
    const style = props.config?.style || '';
    const disabledStyle = props.config?.disabledStyle || 'button-waiting';

    const clicked = (executor: SystemActionExecutor) => {
        if (!enabled)
            return;
        executor().then(msg => {
            setEnabled(true);
            props.afterAction && props.afterAction(msg);
        })
            .catch(HandleErrors());
        setEnabled(false);
    }

    return (
        <SystemActrion name={props.config.actionClass || ''}
            configuration={props.config.actionConfig || {}}
            contextProvider={props.contextProvider}>
            {executor =>
                <div className={`${style} ${enabled ? '' : disabledStyle}`} onClick={() => clicked(executor)}>{icon}{text}</div>}
        </SystemActrion>);
}

function ToolbarLabel(props: ToolbarPositionProps) {
    const text = tactin().configuration.translate(props.option.name);
    return <div className={props.config.style || ''}>{text}</div>
}

function ToolbarFilter(props: ToolbarPositionProps) {
    return <div>{props.option.name}</div>
}
