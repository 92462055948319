import React, { PropsWithChildren } from 'react'
import { BarePropertyControlProps, PropertyControlProps } from './PropertyControl'
import './LabeledControl.css'

type BareControl = (props: BarePropertyControlProps) => JSX.Element;

export function LabeledControl({ children, label, className = '' }: PropsWithChildren<{ label: string, className?: string }>) {
    return <div className={`control ${className}`}>
    <label>{label}</label>
    {children}
</div>
}

export function WithLabel(BareControl: BareControl) {
    return (props: PropertyControlProps) =>
        <LabeledControl label={props.label} className={props.className} >
            <BareControl {...props} className='blockControl' />
        </LabeledControl>
}
