export type PermissionLevel = 'none' | 'read' | 'edit' | 'delete';
export type PermissionType = 'data' | 'interface';

export type PermissionGroup = {
    id: number;
    name: string;
    description: string;
    conditions?: {};
    level: PermissionLevel;
    type: PermissionType;
    dynamic: boolean;
    categories: number[];
}

export const PermissionGroupConverter = {
    toApi: (pg: PermissionGroup): {} => {
        let pgLevel = 0;
        switch(pg.level)
        {
            case 'none': pgLevel = 0; break;
            case 'read': pgLevel = 1; break;
            case 'edit': pgLevel = 2; break;
            case 'delete': pgLevel = 3; break;
        };
        let pgType = 0;
        switch(pg.type)
        {
            case 'data': pgType = 0; break;
            case 'interface': pgType = 1; break;
        };
        return {
            ...pg,
            level: pgLevel,
            type: pgType
        }
    },
    fromApi: (pg: any): PermissionGroup => {
        let id: number = pg.id || 0;
        let name: string = pg.name || '';
        let description: string = pg.description || '';
        let conditions: {} = pg.conditions !== undefined ? pg.conditions : undefined;
        let level : PermissionLevel = 'none';
        if(pg.level) {
            switch(pg.level)
            {
                case 0: level = 'none'; break;
                case 1: level = 'read'; break;
                case 2: level = 'edit'; break;
                case 3: level = 'delete'; break;
            }
        }
        let type: PermissionType = 'data';
        if(pg.type) {
            switch(pg.type)
            {
                case 0: type = 'data'; break;
                case 1: type = 'interface'; break;
            }
        }
        let dynamic: boolean = pg.dynamic ? true : false;
        let categories: number[] = pg.categories || [];

        return { id, name, description, conditions, level, type, dynamic, categories }
    }
}

export const PermissionLevelNames = {
    ['none']: 'No access',
    ['read']: 'Read only',
    ['edit']: 'Edit',
    ['delete']: 'Delete'
}

export const validateInterfacePermission = (pg: PermissionGroup) => {
    if(!pg.name)
        return 'permission group must have a name';
    return '';
}
export const validateDataPermission = (pg: PermissionGroup) => {
    if(!pg.name)
        return 'permission group must have a name';
    if(!pg.conditions)
        return 'permission group must have a condition';
    return '';
}
