import { Failed } from "../utils/api/TactinApi";

export type OptionHandlerClass = ViewerPanelClass | CardTabClass | SystemCardClass | ActionCommandClass | ToolbarClass;

export type ViewerPanelClass = 'StandardTabArea' | 'MainPanel' | 'SmartPanel' | 'FileImporterPanel'
    | 'ListDataViewer' | 'TreeListDataViewer' | 'CalendarDataViewer'
    | 'ChartDataViewer' | 'ReportViewerPanel' | 'EmbeddedReactPanel';
export type CardTabClass = 'General' | 'ListSubPanel' | 'ElementListSubPanel' | 'UserSubPanel';
export type SystemCardClass = 'modifiedFieldConfig' | 'generationConditionConfig' | 'realizationConditionConfig' | "simplifiedSeries";
export type ActionCommandClass = 'LogoutSystemAction' | 'CreateNewItemSystemAction'
    | 'CreateSeriesSystemAction' | 'CreateTemplateSystemAction'
    | 'ChangeUserPasswordSystemAction' | 'CallSystemCommandSystemAction' | 'TestSystemAction' | 'Menu separator';
export type ToolbarClass = 'toolbarButton' | 'toolbarLabel' | 'toolbarFilter';

export const AllowDefaults: OptionHandlerClass[] = [
    'StandardTabArea',
    'MainPanel',
    'SmartPanel',
    'ListDataViewer',
    'TreeListDataViewer',
    'ReportViewerPanel',
    'General',
    'ListSubPanel',
    'ElementListSubPanel'
];

export type Option = {
    id: number;
    usagePlace: number;
    name: string;
    icon: string;
    description: string;
    handlerClass: OptionHandlerClass;
    handlerConfiguration: any;
    order: number;
    permissions: number[];
}

export function handlerShowAs(handlerClass: OptionHandlerClass) {
    switch (handlerClass) {
        case 'CreateNewItemSystemAction':
            return 'New item';
        case 'Menu separator':
            return 'Separator';
        case 'LogoutSystemAction':
            return 'Logout';
        case 'CallSystemCommandSystemAction':
            return 'System command';
        case 'CreateTemplateSystemAction':
            return 'Create template';
        case 'TestSystemAction':
            return 'Test action';
        case 'CreateSeriesSystemAction':
            return 'Create series';
        case 'ChangeUserPasswordSystemAction':
            return 'Change password';

        case 'StandardTabArea':
            return 'Tab panel';
        case 'SmartPanel':
            return 'Smart panel';
        case 'FileImporterPanel':
            return 'File import panel';
        case 'ListDataViewer':
            return 'List panel';
        case 'TreeListDataViewer':
            return 'Tree panel';
        case 'CalendarDataViewer':
            return 'Calendar panel';
        case 'ChartDataViewer':
            return 'Chart panel';
        case 'ReportViewerPanel':
            return 'Report Panel';
        case 'EmbeddedReactPanel':
            return 'Configuration panel';

        case 'General':
            return 'Info page';
        case 'ListSubPanel':
            return 'List page';
        case 'ElementListSubPanel':
            return 'Element list page';
        case 'UserSubPanel':
            return 'User configuration page'

        case 'toolbarButton':
            return 'Button';
        case 'toolbarLabel':
            return 'Label';
        case 'toolbarFilter':
            return 'Filter';
    }
    return handlerClass;
}

const template = (cls: Option['handlerClass'], config: {}): Option => ({
    id: 0,
    usagePlace: 0,
    name: '',
    icon: '',
    description: '',
    handlerClass: cls,
    handlerConfiguration: config,
    order: 0,
    permissions: []
});

export const newMenuOptions: Option[] = [
    template('CreateNewItemSystemAction',
        {}),
    template('CallSystemCommandSystemAction',
        { systemCommandId: 1 }),
    template('LogoutSystemAction', {}),
    template('ChangeUserPasswordSystemAction', {}),
    template('TestSystemAction', {}),
    template('Menu separator', {})
].map((t, i) => ({ ...t, id: i }));

export const newSubPanelOptions: Option[] = [
    template('FileImporterPanel', {}),
    template('ListDataViewer', {}),
    template('TreeListDataViewer', { dataProvider: '', parentColumn: '', filterDataProvider: '' }),
    template('CalendarDataViewer', { dataProvider: '', dateColumn: '', showAsColumn: '' }),
    template('ChartDataViewer',
        {
            dataProvider: '2',
            chartType: 'bar',
            indexLimit: 3,
            includeOthers: true,
            sortBySerie: 0,
            chartOptions: '{"maintainAspectRatio":false,'
                + '"plugins":{"title":{"text":"Statystyka wykonania zadań","display":true}},'
                + '"scales":{"x":{"position":"bottom"},"y":{"position":"left"}}}',
            chartData: '{"labels":"Status",'
                + '"datasets":[{"data":"Obiekt","label":"Statusy zadań",'
                + '"backgroundColor":"rgba(54, 162, 235, 0.2)","borderColor":"rgba(54, 162, 235, 1)","borderWidth":1}]}'
        }),
    template('ReportViewerPanel',
        { reportDefinitionID: 0, dataProvider: '0', start: 0, length: 26, name: 'Lista użytkowników', number: 'Raport-01' }),
    template('EmbeddedReactPanel',
        { panelName: 'ConfigTabPanel' })
].map((t, i) => ({ ...t, id: i }));

export const newPanelOptions: Option[] = [
    template('StandardTabArea',
        { menuUsagePlace: -1 }),
    template('SmartPanel', {}),
    ...newSubPanelOptions
].map((t, i) => ({ ...t, id: i }));

export const newCardPageOptions: Option[] = [
    template('General', {
        columns: [
            {
                elements: [
                    { type: 'field', name: 'name', alias: 'Name' },
                    { type: 'field', name: 'number', alias: 'Number' },
                    { type: 'list', displayLevel: 1 }
                ]
            }
        ]
    }),
    template('ListSubPanel', {}),
    template('ElementListSubPanel', {}),
    template('UserSubPanel', {})
].map((t, i) => ({ ...t, id: i }));

export const newToolbarOptions: Option[] = [
    template('toolbarButton', { actionClass: 'CreateNewItemSystemAction', actionConfig: {} }),
    template('toolbarButton', { actionClass: 'CallSystemCommandSystemAction', actionConfig: {} }),
    template('toolbarButton', { actionClass: 'CreateTemplateSystemAction' }),
    template('toolbarButton', { actionClass: 'CreateSeriesSystemAction' }),
    template('toolbarButton', { actionClass: 'TestSystemAction', actionConfig: {} }),
    template('toolbarButton', { actionClass: 'ChangeUserPasswordSystemAction' }),
    template('toolbarLabel', {}),
    template('toolbarFilter', {})
].map((t, i) => ({ ...t, id: i }));

type ValidatorList = {
    [key: string]: { test: (o: Option) => boolean, error: string }[];
}

const hasName = {
    test: (o: Option) => !!o.name,
    error: 'Name cannot be empty!'
};
const hasIcon = {
    test: (o: Option) => !!o.icon,
    error: 'Icon cannot be empty!'
};
const hasMenuUsagePlace = {
    test: (o: Option) => o.handlerConfiguration?.menuUsagePlace !== undefined,
    error: 'Menu usage place has to be set!'
}

const validatorList: ValidatorList = {
    CreateNewItemSystemAction: [
        hasName, hasIcon,
        { test: o => !!o.handlerConfiguration?.itemTypeEnum, error: 'No item type selected!' }
    ],
    CallSystemCommandSystemAction: [
        hasName, hasIcon,
        {
            test: o =>
                !!o.handlerConfiguration?.systemCommandId
                || !!o.handlerConfiguration?.systemCommandClass,
            error: 'Command ID or command class has to be set!'
        }
    ],

    StandardTabArea: [
        hasName, hasIcon, hasMenuUsagePlace
    ],
    SmartPanel: [],
    FileImporterPanel: [
        hasName, hasIcon,
        { test: o => !!o.handlerConfiguration?.importerName, error: 'Importer name has to be set!' }
    ],
    ListDataViewer: [
        hasName, hasIcon,
        { test: o => !!o.handlerConfiguration?.dataProvider, error: 'Data provider has to be selected!' }
    ],
    TreeListDataViewer: [
        hasName, hasIcon,
        { test: o => !!o.handlerConfiguration?.dataProvider, error: 'Data provider has to be selected!' },
        { test: o => !!o.handlerConfiguration?.parentColumn, error: 'Parent column has to be set!' },
        { test: o => o.handlerConfiguration?.rootId !== undefined, error: 'Root ID has to be a number (0 if no root ID)!' }
    ],
    CalendarDataViewer: [
        hasName, hasIcon,
        { test: o => !!o.handlerConfiguration?.dataProvider, error: 'Data provider has to be selected!' },
        { test: o => !!o.handlerConfiguration?.dateColumn, error: 'Date column has to be set!' },
        { test: o => !!o.handlerConfiguration?.showAsColumn, error: 'Show As column has to be set!' },
    ],
    ChartDataViewer: [
        hasName, hasIcon,
        { test: o => !!o.handlerConfiguration?.dataProvider, error: 'Data provider has to be selected!' },
    ],
    ReportViewerPanel: [
        hasName, hasIcon,
        { test: o => !!o.handlerConfiguration?.reportDefinitionID, error: 'Report definition has to be selected!' },
        { test: o => !!o.handlerConfiguration?.dataProvider, error: 'Data provider has to be selected!' },
    ],
    EmbeddedReactPanel: [
        hasName, hasIcon,
        { test: o => !!o.handlerConfiguration?.panelName, error: 'Panel name has to be selected!' },
    ],

    General: [
        hasName, hasIcon
    ],
    ListSubPanel: [
        hasName, hasIcon,
        { test: o => !!o.handlerConfiguration?.dataProvider, error: 'Data provider has to be selected!' },
    ],
    ElementListSubPanel: [
        hasName, hasIcon,
        { test: o => !!o.handlerConfiguration?.elementCategoryId, error: 'Element category has to be selected!' },
    ],

    toolbarButton: [
        hasName
    ],
    toolbarLabel: [
        hasName
    ],
    toolbarFilter: [
        hasName
    ],
};

export function validateOption(option: Option): Promise<Option> {
    return new Promise((resolve, reject) => {
        const validators = validatorList[option.handlerClass];
        if (validators)
            for (let validator of validators)
                if (!validator.test(option)) {
                    reject(new Failed(validator.error));
                    return;
                }
        resolve(option);
    });
}

export type UsagePlace = {
    id: number;
    name: string;
    type: number;
    options?: Option[]
}

export const UsagePlaceConverter = {
    toApi: (usagePlace: UsagePlace, original?: UsagePlace): {} => {
        let result: any;
        if (original) {
            result = { id: original.id, type: original.type }
            if (!original.name || original.name !== usagePlace.name)
                result.name = usagePlace.name;
        } else {
            result = { ...usagePlace }
        }
        if (original?.options && usagePlace.options) {
            result.options = []
            for (let elm of usagePlace.options) {
                const origCol = original.options.find((c: any) => c.id === elm.id);
                if (origCol)
                    result.options.push(optionToApi(elm, origCol));
                else
                    result.options.push(optionToApi(elm));
            }
            for (let origCol of original.options) {
                const dpCol = (<any[]>result.options).find(c => c.id === origCol.id);
                if (!dpCol)
                    result.options.push({ id: origCol.id, remove: true });
            }
        }
        if (result.options)
            result.options = (<any[]>result.options).map((c, i) => ({ ...c, order: (i + 1) * 10 }))
        return result;
    },
    fromApi: (dp: any): UsagePlace => {
        let id: number = dp.id ? dp.id : 0;
        let name: string = dp.name ? dp.name : '';
        let type: number = dp.type ? dp.type : 0;

        return { id, type, name }
    }
}

function optionToApi(option: Option, original?: Option) {
    let result: any;
    if (original) {
        result = { id: original.id }
        const keys: (keyof Option)[] = ['usagePlace', 'name', 'icon', 'description', 'handlerClass', 'handlerConfiguration', 'order'];
        for (let name of keys)
            if (original[name] && original[name] === option[name])
                continue;
            else
                result[name] = option[name];
    } else {
        result = { ...option };
    }
    return result;
}
