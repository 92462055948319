import React from 'react';
import { ClientItem } from '../../model/item/ItemTypes';
import GroupListViewer from '../list/GroupListViewer';
import { CardSubPanelWrapper } from './CardSubPanelWrapper';

type Props = {
    itemData: ClientItem;
    configuration: any;
}

export default function ListSubPanel({ itemData, configuration }: Props) {

    const dataProvider: string = String(configuration.dataProvider) || '';
    const viewOnly: boolean = configuration.viewOnly || false;
    const blockDynamicGrouping: boolean = configuration.blockGrouping || false;
    const toolbarUsagePlace: number = configuration.toolbarUsagePlace || -1;
    const toolbarConfig: string[] = configuration.toolbar || [];

    return <CardSubPanelWrapper configuration={{ ...configuration, toolbarUsagePlace: -1, toolbar: [] }} hideEmptyToolbar={true} >
        <div className='field-panel'>
            <GroupListViewer dataProvider={dataProvider}
                wildcards={[`ParentItemID:${itemData.item.itemID}`]}
                filters={[]}
                toolbarConfiguration={toolbarConfig}
                decorate
                blockDynamicGrouping={blockDynamicGrouping}
                toolbarUsagePlace={toolbarUsagePlace}
                openOnDblClick={!viewOnly} />
        </div>
    </CardSubPanelWrapper>
}
