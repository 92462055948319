import React, { useEffect, useRef, useState } from 'react';
import { Option } from '../../model/Interface';
import { ActionContextProvider } from '../../model/systemactions/ActionContext';
import { HandleErrors } from '../../utils/api/ApiErrorHandler';
import { api } from '../../utils/api/ApiProvider';
import { Notification } from '../systemactions/SystemAction';
import ToolbarControl from './ToolbarControl';

type ListToolbarProps = {
    usagePlace: number;
    contextProvider?: ActionContextProvider;
    defaultControls: Option[];
    notificationHandler?: (message: Notification) => void;
    hideEmpty?: boolean;
}
export function ListToolbar(props: ListToolbarProps) {

    const [menuOptions, setMenuOptions] = useState<Option[]>([]);
    const isComponentExist = useRef(true);

    useEffect(() => () => { isComponentExist.current = false }, [])

    useEffect(() => {
        if (props.usagePlace < 0)
            processMenuOptions([]);
        else
            api().UserInterface.getOptions(props.usagePlace)
                .then(opts => isComponentExist.current && processMenuOptions(opts))
                .catch(HandleErrors());
    }, [props.usagePlace]);

    const processMenuOptions = (options: Option[]) => {
        setMenuOptions([
            ...props.defaultControls.sort((a, b) => a.order - b.order),
            ...options.sort((a, b) => a.order - b.order)
        ]);
    }

    if (props.hideEmpty && menuOptions.length === 0)
        return null;

    return (
        <div className='toolbar'>
            {menuOptions.map((o, i) =>
                <ToolbarControl key={i} option={o} contextProvider={props.contextProvider}
                    afterAction={m => m && props.notificationHandler && props.notificationHandler(m)} />)}
        </div>
    );
}
