import { HandleCleanup, HandleErrors } from "../../../utils/api/ApiErrorHandler";
import { ListDataResult } from "../../../utils/api/Results";
import { CacheNode } from "../cache/CacheNode";

class LoadStack {
    parent: CacheNode;
    start: number;
    length: number;
    loading: boolean;

    constructor(parent: CacheNode, start: number, length: number) {
        this.parent = parent;
        this.start = start;
        this.length = length;
        this.loading = false;
    }
}

type LoaderFunc = (parent: CacheNode, start: number, length: number) => Promise<ListDataResult>;
type LoadedCallback = (result: string[][], parent: CacheNode, start: number, length: number, timestamp: number) => void;

export class CacheLoaderStack {

    defaultCacheSegmentSize: number;

    loadStack: LoadStack[];
    loadCount: number;

    constructor(defaultCacheSegmentSize: number) {
        this.loadStack = [];
        this.loadCount = 0;
        this.defaultCacheSegmentSize = defaultCacheSegmentSize;
    }

    loader?: LoaderFunc;
    loadedCallback?: LoadedCallback;
    allDataLoaded?: (loadCount: number) => void;

    pushLoad(parent: CacheNode, start: number, length: number, minStart: number, maxEnd: number): void {
        const newStart = Math.max(start - Math.floor(this.defaultCacheSegmentSize / 2), minStart);
        const newLength = Math.min(
            Math.max(length, this.defaultCacheSegmentSize),
            maxEnd - newStart);

        for (const entry of this.loadStack) {
            if (entry.parent != parent || entry.loading)
                continue;
            if (newStart < entry.start) {
                if (newStart + newLength < entry.start)
                    continue;
                entry.length = entry.start + entry.length - newStart;
                entry.start = newStart;
                if (newLength > entry.length)
                    entry.length = newLength;
                return;
            } else if (newStart < entry.start + entry.length) {
                if (newStart + newLength > entry.start + entry.length)
                    entry.length = newStart + newLength - entry.start;
                return;
            }
        }

        this.loadStack.push(new LoadStack(parent,
            newStart,
            newLength));
    }

    loadMissing(): void {
        if (this.loadStack.length === 0) {
            if (this.allDataLoaded)
                this.allDataLoaded(this.loadCount);
            this.loadCount = 0;
            return;
        }

        const ls = this.loadStack[0];
        if (ls.loading)
            return;
        ls.loading = true;

        if (this.loader)
            this.loader(ls.parent, ls.start, ls.length)
                .then(result => {
                    if (this.loadedCallback)
                        this.loadedCallback(result.listData.data, ls.parent, ls.start, ls.length, result.timestamp);
                    this.loadCount++;
                    this.loadStack = this.loadStack.filter(v => v !== ls);
                    this.loadMissing();
                    ls.loading = false;
                })
                .catch(HandleCleanup(() => {
                    ls.loading = false;
                }))
                .catch(HandleErrors(true));
        else
            ls.loading = false;
    }

    clear(): void {
        this.loadStack = this.loadStack.filter(ls => ls.loading);
    }
}
