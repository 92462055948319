import React from 'react';
import { DateControl } from '../../propertycontrols/DateControl';
import './SeriesModifiedDate.css';

type IntervalType = 'day' | 'month' | 'year';

type TGenState = {
    dateMode: 'static' | 'dynamic';
    intervalType?: IntervalType;
    staticDate?: string;        // date in our JSON format (yyyy-MM-dd HH:mm)
    dateInterval?: number;
}

const options = [
    { value: 'day', text: 'dni' },
    { value: 'month', text: 'miesięcy' },
    { value: 'year', text: 'lat' }
]

type SeriesGenerateDateProps = {
    value: TGenState;
    onChange: (value: TGenState) => void;
}

export default function SeriesGenerateDate({ value, onChange }: SeriesGenerateDateProps) {

    const change = (v: Partial<NonNullable<TGenState>>) =>
        onChange({ ...value, ...v });

    const setStaticMode = () => {
        if (value.dateMode !== 'static')
            change({ dateMode: 'static' })
    }

    const setDynamicMode = () => {
        if (value.dateMode !== 'dynamic')
            change({ dateMode: 'dynamic' })
    }

    return <div className='generateContainer'>
        <div className='generateMode' onFocus={setStaticMode}>
            <RadioBox checked={value.dateMode === 'static'}
                label='Data statyczna'
                onClick={setStaticMode} />
            <DateControl label=''
                value={value.staticDate}
                readOnly={false} className='staticDateControl'
                configuration={{ format: 'yyyy-MM-dd HH:mm' }}
                onChange={(v) => change({ staticDate: v })} />
        </div>
        <div className='generateMode' onFocus={setDynamicMode}>
            <RadioBox checked={value.dateMode === 'dynamic'}
                label='Data dynamiczna'
                onClick={setDynamicMode} />
            <input className='inputBox' type='number' min={0}
                value={value.dateInterval}
                onChange={(e) => change({ dateInterval: +e.currentTarget.value })} />
            <select value={value.intervalType ?? 'day'} onChange={(e) => change({ intervalType: e.target.value as IntervalType })}>
                {options.map(i => <option key={i.value} value={i.value} >{i.text}</option>)}
            </select>
            <span>od dzisiaj</span>
        </div>
    </div>
}

function RadioBox({ checked, label, onClick }: { checked: boolean, label: string, onClick: () => void }) {
    return <><input type='radio' name='ganerate' checked={checked} onChange={() => { }} />
        <label onClick={onClick}>{label}</label></>
}
