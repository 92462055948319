import { ClientItem, ItemCategory } from "../item/ItemTypes";
import { ColumnFilter } from "../list/ColumnFilter";

export type ActionContextType = 'none' | 'item' | 'category' | 'type' | 'list';

export type ActionContextProvider = (type: ActionContextType) => ActionContext | null;

export class ActionContext {
    type: ActionContextType;
    id: number;

    constructor(type: ActionContextType, id: number) {
        this.type = type;
        this.id = id;
    }
}

export type ListAdditionalData = {
    sortColumn: string;
    sortDirection: string;
    filters: ColumnFilter[];
    wildcardValues: string[];
    grouping: string[];
    rowId?: number;
}
export class ListActionContext extends ActionContext {
    data: ListAdditionalData;
    constructor(id: number, listData: ListAdditionalData) {
        super('list', id);
        this.data = listData;
    }
}

export class ItemActionContext extends ActionContext {
    item: ClientItem;
    constructor(item: ClientItem) {
        super('item', item.item.itemID);
        this.item = item;
    }
}

export class CategoryActionContext extends ActionContext {
    category: ItemCategory;
    constructor(category: ItemCategory) {
        super('category', category.id);
        this.category = category;
    }
}
