import { Option, UsagePlace, validateOption } from '../model/Interface';
import { HandleErrors } from '../utils/api/ApiErrorHandler';
import { UserInterfaceApi } from '../utils/api/UserInterfaceApi';
import { useAsyncArray } from '../utils/EntityStore';

export default function useInterfaceStore(token: string) {
    const [getStdCfg, setStandardConfig] = useAsyncArray<Map<string, string>>(new Map(), UserInterfaceApi(token).getHandlerConfig, HandleErrors())
    const [getUsagePlaces, setUsagePlaces] = useAsyncArray<UsagePlace[]>([], UserInterfaceApi(token).getUsagePlaces, HandleErrors());
    const [getOptions, setOptions, loadOptions] = useAsyncArray<Option[]>([], UserInterfaceApi(token).getAllOptions, HandleErrors());

    const getStandardConfig = (className: string) => JSON.parse(getStdCfg().get(className) || '{}')

    const saveStandardConfig = (className: string, config: string) =>
        UserInterfaceApi(token)
            .saveHandlerConfig(className, config)
            .then(setStandardConfig)
            .catch(HandleErrors());

    const originalUsagePlace = (usagePlace: UsagePlace) => {
        const original = getUsagePlaces().find(u => u.id === usagePlace.id);
        if (!original)
            return undefined;
        return {
            ...original,
            options: getOptions().filter(o => o.usagePlace === original.id)
        }
    }

    const getToolbars = () => getUsagePlaces().filter(u => u.type === 1 && u.name !== 'TactinActionButton');
    const getCards = () => getUsagePlaces().filter(u => u.type === 2);
    const saveUsagePlace = (usagePlace: UsagePlace) =>
        UserInterfaceApi(token)
            .saveUsagePlace(usagePlace, originalUsagePlace(usagePlace))
            .then(setUsagePlaces)
            .then(r => { loadOptions(); return r; })
            .catch(HandleErrors());
    const removeUsagePlace = (id: number) =>
        UserInterfaceApi(token)
            .removeUsagePlace(id)
            .then(setUsagePlaces)
            .then(r => { loadOptions(); return r; })
            .catch(HandleErrors());

    const saveOption = (option: Option) =>
        validateOption(option)
            .then(UserInterfaceApi(token).saveOption)
            .then(setOptions)
            .catch(HandleErrors());
    const removeOption = (id: number) =>
        UserInterfaceApi(token)
            .removeOption(id)
            .then(setOptions)
            .catch(HandleErrors());
    const reorderOptions = (ids: number[]) =>
        UserInterfaceApi(token)
            .reorderOptions(ids)
            .then(setOptions)
            .catch(HandleErrors());
    const addPermission = (optionId: number, permissionId: number) =>
        UserInterfaceApi(token)
            .addPermission(optionId, permissionId)
            .then(setOptions)
            .catch(HandleErrors());
    const removePermission = (optionId: number, permissionId: number) =>
        UserInterfaceApi(token)
            .removePermission(optionId, permissionId)
            .then(setOptions)
            .catch(HandleErrors());

    return {
        getStandardConfig,
        saveStandardConfig,
        getUsagePlaces,
        getToolbars,
        getCards,
        saveUsagePlace,
        removeUsagePlace,
        getOptions,
        saveOption,
        removeOption,
        reorderOptions,
        addPermission,
        removePermission
    }
}
