import { tactin } from "../../utils/TactinGlobals";
import { api } from "../../utils/api/ApiProvider";
import { OpenItemEvent } from "../events/TactinEvents";
import { ActionContextProvider, ListActionContext } from "./ActionContext";

export function CreateNewItemSystemAction(configMap: any, contextProvider?: ActionContextProvider) {
    return async () => {
        const categoryID = configMap.categoryID || 0;
        const contextMap: { [key: string]: string } = configMap.contextMap || {};
        let openEvent;
        if (contextMap && contextProvider && contextProvider('item'))
            openEvent = new OpenItemEvent().byType(categoryID, '', contextMap, contextProvider);
        else
            openEvent = new OpenItemEvent().byType(categoryID);

        tactin()?.eventBus.notify(openEvent);
        return null;
    }
}

export function RestoreDeletedItemAction(configMap:any, contextProvider?:ActionContextProvider) {
    return async () => {
        if (!contextProvider){
            return null;
        }
        const listInfo = contextProvider('list');
        if (!listInfo){
            return null;
        }
        
        const rowId = (listInfo as ListActionContext).data.rowId;
        
        if (rowId && rowId > 0){
            (listInfo as ListActionContext).data.rowId = -1;
            let openEvent = new OpenItemEvent().byItemId(rowId);
            await api().Item.clearDeletedFlag(rowId);
            tactin()?.eventBus.notify(openEvent);
        }

        return null;
    }
}
