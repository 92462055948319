import { DateUtils } from "../../utils/DateUtils";
import { PropertyType } from "../Property";
import { ItemBuiltInFields } from "./Item";
import { Item } from "./ItemTypes";

function parseValueToString(value: any, type: PropertyType) {
    if(value === null)
        return null;
    switch (type) {
        case 'BIT':
            if(typeof value === 'boolean')
                return String(value);
            else if(typeof value === 'number')
                return String(value !== 0);
            break;
        case 'DATE':
            if(value instanceof Date)
                return DateUtils.toJSONDate(value);
            break;
        case 'DECIMAL':
            if(typeof value === 'number')
                return String(value);
            break;
        case 'FILELINK':
        case 'IMAGE':
        case 'INTEGER':
        case 'ITEM2ITEM':
        case 'VALUELIST':
            if(typeof value === 'number')
                return String(value);
            break;
        case 'ITEM2MANYITEMS':
            if(value instanceof Array)
                return value.join(', ');
            break;
        case 'RICHTEXT':
        case 'TEXT':
            if(typeof value === 'string')
                return value;
            break;
        case 'CATEGORY':
        case 'TYPE':
        case 'USER':
        default:
            break;
    }
    return String(value);
}

function parseValueFromString(value: string | null, type: PropertyType): any {
    if(value === null)
        return null;

    switch (type) {
        case 'BIT':
            return value === 'true' ? true : (value === 'false' ? false : null);
        case 'DATE':
            return DateUtils.fromJSONDate(value);
        case 'DECIMAL':
            return Number(value);
        case 'FILELINK':
        case 'IMAGE':
        case 'INTEGER':
        case 'ITEM2ITEM':
        case 'VALUELIST':
            return Number(value);
        case 'ITEM2MANYITEMS':
            return value.split(/,/).map(s => Number(s.trim()));
        case 'RICHTEXT':
        case 'TEXT':
            return value;
        case 'CATEGORY':
        case 'TYPE':
        case 'USER':
        default:
            break;
    }

    return null;
}

function getFieldValue(item: Item, field: string): any {
    if(field === 'category' || field === 'categoryID')
        return item.categoryID;
    for(const builtIn of ItemBuiltInFields.keys())
        if(field === builtIn)
            return (item as any)[builtIn];
    const prp = field.match(/^pv_(\d+)$/);
    if(prp)
        return item.properties[+prp[1]]?.value;
    return undefined;
}
function setFieldValue(item: Item, field: string, value: any, showAs?: string) {
    if(field === 'category' || field === 'categoryID')
        return item.categoryID = Number(value);
    for(const builtIn of ItemBuiltInFields.keys())
        if(field === builtIn)
            return (item as any)[builtIn] = value;
    const prp = field.match(/^pv_(\d+)$/);
    if(prp) {
        const id = Number(prp[1]);
        if(!item.properties[id])
            item.properties[id] = {
                type: item.category?.properties.find(p => p.id)?.dataType || 'TEXT',
                value, showAs
            }
        else {
            item.properties[id].value = value;
            item.properties[id].showAs = showAs;
        }
        return item.properties[id].value;
    }
    return undefined;
}

export const ItemUtils = {
    getFieldValue,
    setFieldValue,
    parseValueToString,
    parseValueFromString
}
