import TactinEventBus from "../model/events/EventBus";
import { Item } from "../model/item/ItemTypes";
import { TactinConfigData } from "../model/TactinConfig";
import Wildcards from "../utils/Wildcards";
import { LoginResult } from "./api/Results";
import { DateUtils } from './DateUtils';

declare global {
    interface Window {
        tactinGlobalObject: ReturnType<typeof getGlobalStateObj>;
    }
}

export function tactin() {
    return window.tactinGlobalObject;
}

export function initGlobalState(eventBus: TactinEventBus, tactinConfig: TactinConfigData, loginData: LoginResult) {
    window.tactinGlobalObject = getGlobalStateObj(eventBus, tactinConfig, loginData);
}

function getGlobalStateObj(eventBus: TactinEventBus, tactinConfig: TactinConfigData, loginData: LoginResult) {
    return {
        eventBus: eventBus,
        configuration: {
            sessionTimeout: () => tactinConfig.clientConfig.sessionTimeout,
            systemInfo: (key: string) => tactinConfig.clientConfig.systemInfo[key.toLocaleLowerCase()] || '',
            usagePlace: (id: number, by: 'category' | 'type', template: boolean) => {
                let list;
                if (by === 'category')
                    list = `category${template ? 'Template' : ''}`;
                else
                    list = `standard${template ? 'Template' : 'Item'}Card`
                return (tactinConfig.clientConfig as any)[`${list}UsagePlaces`][String(id)] || 0
            },
            translate: (key: string) => tactinConfig.clientConfig.translation[key] ?? key,
            textTemplates: () => [...tactinConfig.clientConfig.richTextTemplates],
            itemSeries: {
                defaultConfig: (type: number, categoryId: number) => {
                    const configSet = JSON.parse(tactinConfig.clientConfig.itemSeriesDefaultConfig);
                    const cfg = configSet[`${type}-${categoryId}`] ?? configSet[`${type}-x`] ?? configSet['x-x'];
                    return {
                        modifiedFields: cfg?.modifiedFields ?? {},
                        generationCondition: cfg?.generationCondition ?? {},
                        realizationCondition: cfg?.realizationCondition ?? {}
                    }
                },
                simplifiedInterface: () => tactinConfig.clientConfig.itemSeriesSimplifiedInterface
            }
        },
        login: {
            language: () => loginData.languageId,
            loggedUser: () => loginData.loggedUser
        },
        standardHandler: (key: string) => JSON.parse(tactinConfig.standardHandlers.get(key) || '{}'),
        substituteWildcards: (text: string) => substituteWildcards(text, loginData.loggedUser)
    }
}

function substituteWildcards(text: string, user?: Item): string {
    const builtIn = Wildcards.getBuiltInEmptyWildcards();
    builtIn.set("now", DateUtils.formatDate(Date.now(), "yyyy-MM-dd HH:mm"));
    builtIn.set("today", DateUtils.formatDate(Date.now(), "yyyy-MM-dd 00:00"));
    return Wildcards.substituteWildcards(text,
        builtIn,
        user);
}
