import React, { useState } from 'react';
import { LabeledControl } from './LabeledControl';
import { BareLinkControl } from './LinkControl';
import { PropertyControlProps } from './PropertyControl';
import './LinkControl.css'

export const ImageControl = (props: PropertyControlProps) => {
    const [currentImg, setCurrentImg] = useState<string>('');

    return <LabeledControl label={props.label} className={`${props.className ? props.className : ''} ${currentImg ? 'img-control' : ''}`} >
        <BareLinkControl 
            {...props} 
            className='blockControl' 
            onlyImgFiles={true} 
            currentImg={currentImg} 
            setCurrentImg={setCurrentImg}
        />
    </LabeledControl>
}
