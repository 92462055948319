import React from 'react';
import { ImageBox } from '../basic/ImageBox';
import './CardRibbon.css'

type Props = {
    icon: string;
    headline: string;
}

export default function CardRibbon({icon, headline}: Props) {
    return <ImageBox iconName={icon} label={headline} className='card-ribbon' />
}
