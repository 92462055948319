import React, { useEffect, useState } from 'react';
import { PermissionGroup, PermissionType } from '../../../model/PermissionGroup';
import { HandleErrors } from '../../../utils/api/ApiErrorHandler';
import { api } from '../../../utils/api/ApiProvider';
import { tactin } from '../../../utils/TactinGlobals';
import './UserPermissions.css';

type UserPermissionsProps = {
    itemId: number;
    permissions: number[];
    onChange: (v: number[]) => void;
    disabled?: boolean;
}
export function UserPermissions(props: UserPermissionsProps) {
    const tr = tactin().configuration.translate;
    const [tab, setTab] = useState<PermissionType>('data');

    const [permissions, setPermissions] = useState<PermissionGroup[]>([]);
    const reload = () => {
        api().Config.PermissionGroup.list().then(l => setPermissions(l)).catch(HandleErrors());
    }

    useEffect(() => reload(), [props.itemId]);

    const toggle = (id: number, check: boolean) => {
        if (check)
            props.onChange([...props.permissions, id].sort((a, b) => a - b));
        else
            props.onChange(props.permissions.filter(p => p !== id));
    }

    const tabPermissions = permissions.filter(p => p.type === tab);

    return <div className={`bordered-section`}>
        <div className='permission-tabs'>
            <button disabled={props.disabled} onClick={() => setTab('data')}>{tr('Dane')}</button>
            <button disabled={props.disabled} onClick={() => setTab('interface')}>{tr('Interfejs')}</button>
        </div>
        <div className='permission-list'>
            {tabPermissions.map(p => <div className='row'>
                <div className='cell'>
                    <label>
                        <input type="checkbox" checked={props.permissions.includes(p.id)} readOnly
                            onChange={e => toggle(p.id, e.currentTarget.checked)} />
                        {p.name}
                    </label>
                </div>
                <div className='cell'>{p.description}</div>
            </div>)}
        </div>
    </div>;
}
