
/**
 * Encodes the delete information in the showAs.
 *
 * @param showAs unencoded value
 * @return showAs value with delete informations encoded
 */
export function encodeShowAsDeleted(showAs: string): string {
    return `---${showAs}---`;
}

/**
 * Checks if the show as contains encoded delete information.
 *
 * @param showAs showAs with encoded delete informations
 * @return <code>true</code> - item is deleted</br>
 * <code>false</code> - item isn't deleted or show as hadn't had any
 * information encoded
 */
export function isShowAsDeleted(showAs: string): boolean {
    return showAs.startsWith('---') && showAs.endsWith('---');
}

/**
 * Returns an item ShowAs without the delete information encoded in it.
 *
 * <p>If the argument doesn't contain delete informations it's returned
 * as-is.</p>
 *
 * @param showAs value with deleted information encoded in it
 * @return showAs value without encoded information
 */
export function decodeShowAsDeleted(showAs: string): string {
    if(isShowAsDeleted(showAs))
        return showAs.substring(3, showAs.length - 3);
    else
        return showAs;
}

export default {
    encodeShowAsDeleted,
    isShowAsDeleted,
    decodeShowAsDeleted
}
