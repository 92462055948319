import React, { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import { tactin } from '../../../utils/TactinGlobals';
import './WildcardEditor.css';

type WildcardEditorProps = {
    value: string[];
    onChange: (v: string[]) => void;
    className?: string;
    disabled?: boolean;
}
export function WildcardEditor(props: WildcardEditorProps) {
    const tr = tactin().configuration.translate;
    const [newWildcard, setNewWildcard] = useState<string | null>(null);
    const newRowRef = useRef<WildcardEditorRowRef>(null);

    const wildcards = props.value.map(parseWildcard).filter(([key]) => key);

    const change = (id: number, v: [string, string]) => {
        const set: [string, string][] = id < 0 ? [...wildcards, v] : wildcards;
        props.onChange(set.map((w, i) => i === id ? v.join(':') : w.join(':')));
        setNewWildcard(null);
    }
    const remove = (id: number) => {
        props.onChange(wildcards.filter((w, i) => i !== id).map(w => w.join(':')));
    }

    const validator = (id: number) => (key: string) => {
        if(!key)
            return tr('Klucz nie może być pusty.');
        else if(wildcards.find((w, i) => w[0] === key && i !== id))
            return tr('Podany klucz jest już zdefiniowany.');
        else
            return '';
    }

    useLayoutEffect(() => {
        if(newRowRef.current !== null && !newWildcard)
            newRowRef.current.focus();
    }, [newWildcard])

    return <div className={`wildcard-editor ${props.className || ''}`}>
        {wildcards.map(([key, val], i) =>
            <WildcardEditorRow key={i}
                disabled={props.disabled}
                wildcard={[key, val]}
                validateKey={validator(i)}
                onChange={v => change(i, v)}
                onDelete={() => remove(i)} />)}
        {newWildcard === null ?
            <button className='cell new-row' disabled={props.disabled} onClick={() => setNewWildcard('')}>{tr('Dodaj nowy')}</button> :
            <WildcardEditorRow key={wildcards.length} ref={newRowRef} wildcard={parseWildcard(newWildcard)}
                validateKey={validator(-1)}
                onChange={v => change(-1, v)}
                onDelete={() => setNewWildcard(null)} />}
    </div>;
}

function parseWildcard(w: string): [string, string] {
    const [key, ...rest] = w.split(/:/);
        const val = rest ? rest.join(':') : '';
        if(!key)
            return ['',''];
        else
            return [key, val];
}

type WildcardEditorRowProps = {
    wildcard: [string, string];
    validateKey: (key: string) => string;
    onDelete: () => void;
    onChange: (v: [string, string]) => void;
    disabled?: boolean;
}
type WildcardEditorRowRef = {
    focus: () => void;
}
const WildcardEditorRow = forwardRef<WildcardEditorRowRef, WildcardEditorRowProps>(function(props, ref) {
    useImperativeHandle(ref, () => ({
        focus: () => keyRef.current?.focus()
    }));
    const tr = tactin().configuration.translate;
    const [key, setKey] = useState(props.wildcard[0]);
    const [value, setValue] = useState(props.wildcard[1]);

    const keyRef = useRef<HTMLInputElement>(null);
    const valRef = useRef<HTMLInputElement>(null);
    const delRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        setKey(props.wildcard[0]);
        setValue(props.wildcard[1]);
    }, [...props.wildcard]);

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === 'Escape') {
            if(props.wildcard[0] === '')
                props.onDelete();
            else {
                setKey(props.wildcard[0]);
                setValue(props.wildcard[1]);
            }
        } else if(e.key === 'Enter') {
            e.currentTarget.blur();
        }
    }

    return <div className='row'>
        <input ref={keyRef}
            className={`cell ${props.validateKey(key) ? 'invalid' : ''}`}
            type='text'
            value={key}
            onChange={e => setKey(e.currentTarget.value.replaceAll(/:/g,''))}
            onBlur={e => {
                if(e.relatedTarget !== delRef.current) {
                    if(props.validateKey(key))
                        e.currentTarget.focus();
                    else if(e.relatedTarget !== valRef.current)
                        props.onChange([key, value]);
                }
            }}
            title={props.validateKey(key)}
            onKeyDown={onKeyDown}
            disabled={props.disabled} />
        <input ref={valRef}
            className='cell'
            type='text'
            value={value}
            onChange={e => setValue(e.currentTarget.value)}
            onBlur={e =>
                e.relatedTarget !== delRef.current && e.relatedTarget !== keyRef.current
                    && props.onChange([key, value])
            }
            onKeyDown={onKeyDown}
            disabled={props.disabled} />
        <button ref={delRef} className='cell delete'
            onBlur={e => e.relatedTarget !== keyRef.current && e.relatedTarget !== valRef.current && e.currentTarget.focus()}
            onClick={props.onDelete}
            disabled={props.disabled}>X</button>
    </div>
});
