import { tactin } from "../../utils/TactinGlobals";
import { PropertyType } from "../Property";
import { itemDiff } from "./Diff";
import { AddOns, ClientItem, Item, ItemCategory, ItemProperties, PropertyValue } from "./ItemTypes";
import { ItemUtils } from "./Utils";

export const ItemBuiltInFields = new Map<string, { alias: string, type: PropertyType }>(
    [
        ['itemID', { alias: 'Inner ID', type: 'INTEGER' }],
        ['name', { alias: 'Name', type: 'TEXT' }],
        ['number', { alias: 'Number', type: 'TEXT' }],
        ['description', { alias: 'Description', type: 'RICHTEXT' }],
        ['showAs', { alias: 'Show as', type: 'TEXT' }],
        ['category', { alias: 'Category', type: 'CATEGORY' }],
        ['type', { alias: 'Type', type: 'TYPE' }],
        ['createdBy', { alias: 'Created by', type: 'USER' }],
        ['createdAt', { alias: 'Create date', type: 'DATE' }],
        ['modifiedBy', { alias: 'Last modified by', type: 'USER' }],
        ['modifiedAt', { alias: 'Modify date', type: 'DATE' }]
    ]);

let newId = -1;
function getNewId() {
    return newId--;
}

export function createNewItem(category: ItemCategory, ownerId?: number): ClientItem {

    const item: Item = {
        type: category.typeId,
        categoryID: category.id,
        category: category,
        itemID: getNewId(),
        properties: category.properties.reduce((res, icp) => {
            const defaultValue = icp.defaultValue ? tactin().substituteWildcards(icp.defaultValue) : icp.defaultValue;
            res[icp.id] = {
                type: icp.dataType,
                value: ItemUtils.parseValueFromString(defaultValue, icp.dataType)
            }
            return res;
        }, {} as ItemProperties),
        elements: category.elementCategories.map(elmCatId => ({ categoryId: elmCatId, elements: [], isIndividualized: true })),
        elementOwnerId: ownerId || null,

        name: null, number: null, description: null, showAs: null, createdBy: 0,
        createdAt: null, modifiedBy: 0, modifiedAt: null, template: false,
        templateId: null, deleted: false, individualized: true
    };

    return { item, permission: 'delete' };
}

export function copyAsNew(item: Item, template: boolean, ownerId?: number): Item {
    const newId = getNewId();
    return {
        type: item.type,
        categoryID: item.categoryID,
        category: item.category,
        itemID: newId,
        properties: { ...item.properties },
        elements: item.elements.map(elmCat => ({ ...elmCat, elements: elmCat.elements.map(elm => copyAsNew(elm, template, newId)) })),
        elementOwnerId: ownerId || null,

        name: item.name,
        number: item.number,
        description: item.number,
        template: template,

        showAs: null, createdBy: 0, createdAt: null, modifiedBy: 0, modifiedAt: null,
        templateId: null, deleted: false, individualized: true
    }
}

export function moveToClientSpace(item: Item) {
    item.itemID = getNewId();
    item.elements.forEach(l => {
        l.elements.forEach(el => {
            el.itemID = getNewId();
            el.elementOwnerId = item.itemID;
        });
    });
}

export function setNewCategory(item: Item, newCategory: ItemCategory) {
    const missingPvs: string[] = [];
    const missingElementsCategories: string[] = [];

    const bareItem = createNewItem(item.category).item;
    const diff = itemDiff(bareItem, item);

    const newItem = createNewItem(newCategory).item;
    const itemFields: (keyof Item)[] = ['itemID', 'name', 'number',
        'description', 'showAs', 'createdBy', 'createdAt', 'modifiedBy',
        'modifiedAt', 'elementOwnerId', 'version'];
    itemFields.forEach(key => (newItem[key] as any) = item[key]);

    const diffProps = diff?.properties
    if (diffProps) {
        Object.keys(diffProps)
            .map(key => [Number(key), diffProps[Number(key)]] as [number, PropertyValue])
            .forEach(([id, pv]) => {
                if (!newItem.properties[id] && pv.value !== null) {
                    const prpName = item.category.properties.find(icp => icp.id === Number(id))?.name;
                    if (prpName) missingPvs.push(prpName);
                    if ((diff?.itemID || 0) > 0)
                        newItem.properties[id] = { type: pv.type, value: null };
                } else {
                    newItem.properties[id] = pv;
                }
            });
    }

    if (item.elements) {
        item.elements.forEach(list => {
            if (!list.elements.length)
                return;
            const newElmList = newItem.elements.find(e => e.categoryId === list.categoryId);
            if (newElmList)
                newElmList.elements = list.elements;
            else
                missingElementsCategories.push(list.elements[0].category.name);
        });
    }

    return { missingPvs, missingElementsCategories, newItem };
}


export function deepCopy(item: Item): Item {
    return {
        ...item,
        createdAt: item.createdAt ? new Date(item.createdAt) : null,
        modifiedAt: item.modifiedAt ? new Date(item.modifiedAt) : null,
        properties: deepCopyProperties(item.properties),
        elements: item.elements.map(elm => ({ ...elm, elements: elm.elements.map(e => deepCopy(e)) })),
        addOns: item.addOns ? deepCopyAddOns(item.addOns) : undefined
    };
}

function deepCopyProperties(properties: ItemProperties): ItemProperties {
    const result: ItemProperties = {};

    for (const key in properties) {
        if (Object.prototype.hasOwnProperty.call(properties, key))
            result[key] = { ...properties[key] }
    }
    return result;
}

function deepCopyAddOns(addOns: AddOns): AddOns {
    const result: AddOns = {}

    if (addOns.report) result.report = { ...addOns.report }
    if (addOns.user) result.user = { ...addOns.user, userWildcards: [...addOns.user.userWildcards] }
    if (addOns.series_instance) result.series_instance = { ...addOns.series_instance }
    if (addOns.series)
        result.series = {
            ...addOns.series,
            generationConfig: JSON.parse(JSON.stringify(addOns.series.generationConfig)),
            modifyConfig: JSON.parse(JSON.stringify(addOns.series.modifyConfig)),
            realizationConfig: JSON.parse(JSON.stringify(addOns.series.realizationConfig)),
        }
    return result;
}
