import React, { Fragment, useEffect, useState } from 'react';
import { ShowMessageEvent, TactinEvent } from '../../model/events/TactinEvents';
import { tactin } from '../../utils/TactinGlobals'

export default function TactinMessage() {
    useEffect(() => {
        return tactin()?.eventBus.register(messageHandler);
    }, []);

    const messageHandler = (event: TactinEvent) => {
        if(event instanceof ShowMessageEvent)
            setMessage(event.message);
    }
    const [message, setMessage] = useState('');

    if(message)
        return (
        <div className='frameless-popup'>
            <div className='background' onClick={() => setMessage('')} ></div>
            <div className = 'message-box' >
                <div className='body'>
                    {message.split('\n').flatMap((l, i) => [<Fragment key={i+l}>{l}</Fragment>, <br/>])}                
                </div>                
                <div className='footer'>
                    <button autoFocus tabIndex={0} onClick={() =>setMessage('')} onKeyDown={e =>{ 
                        if(e.key==='Escape') {
                            setMessage(''); 
                            e.stopPropagation();
                        }
                    }}>OK</button>
                </div>
            </div>
        </div>);
    else
        return null;
}
