import React, { useState } from 'react';
import { TextTemplate } from '../../../model/ConfigurationEntities';
import { tactin } from '../../../utils/TactinGlobals';
import PopupBox from '../../basic/PopupBox';

type TemplatesProps = {
	showPopup: (value: boolean) => void;
	insertLink: (value: string) => void;
}

export default function TemplatePopup({ showPopup, insertLink }: TemplatesProps) {
    const [chosenTemplate, setChosenTemplate] = useState<TextTemplate>();
    const [lastSelectedTemplate, setLastSelectedTemplate] = useState<number>(-1);
    function chooseTemplate(temp: TextTemplate): void {
        setChosenTemplate(temp);
        if (lastSelectedTemplate === temp.id){
            insertLink(tactin().substituteWildcards(temp.text));
            showPopup(false);
        }
        setLastSelectedTemplate(temp.id);
    }

    return (
        <PopupBox
            title={<span>create template</span>}
            className={'some'}
            buttons={[
                (onClickCreator) => (<div key={2} className='button' onClick={onClickCreator('No')}>Anuluj</div>),
                (onClickCreator) => (<div key={1} className='button' onClick={onClickCreator('Yes')}>OK</div>)
            ]}
            onClose={(s?: string) => {
                if (s === 'Yes') {
                    if (chosenTemplate)
                        insertLink(tactin().substituteWildcards(chosenTemplate.text));
                    showPopup(false);
                } else
                    showPopup(false);
            }} >
            <select className='templatePopupList' size={10} >
                {tactin().configuration.textTemplates().map(item => <option key={item.id} onClick={(() => { chooseTemplate(item) })}>{item.name}</option>)}
            </select>
            <div>
                <div>Preview</div>
                {
                    chosenTemplate ?
                    <div className='template_preview' dangerouslySetInnerHTML={{__html: tactin().substituteWildcards(chosenTemplate.text)}}></div>
                    : <div className="template_preview"></div>
                }
            </div>
        </PopupBox>);
}
