import { Property } from "../Property";
import { AddOns, ElementList, Item, PropertyValue, ReportAddOn, SeriesAddOn, SeriesInstanceAddOn, UserAddOn } from "./ItemTypes";

type diffMode = 'normal' | 'keepResets' | 'save'

const fixTabsInStr = (s:string|null) => s ? s.replace(/\t/g, " ") : s;
const fixTabsInProperty = (prp:PropertyValue) => prp.type === 'TEXT' ? {...prp, value:fixTabsInStr(prp.value)} : prp;

export function itemDiff(original: Item, actual: Item, mode: diffMode = 'normal'): Partial<Item> | null {
    const result: Partial<Item> = {};
    const newItem = (actual.itemID < 1);
    const hasTemplate = ((actual.templateId ?? 0) > 0)

    if (original.name !== actual.name || (newItem && !hasTemplate))
        result.name = fixTabsInStr(actual.name);
    if (original.number !== actual.number || (newItem && !hasTemplate))
        result.number = fixTabsInStr(actual.number);
    if (original.description !== actual.description || (newItem && !hasTemplate))
        result.description = actual.description;
    if (original.showAs !== actual.showAs || (newItem && !hasTemplate))
        result.showAs = fixTabsInStr(actual.showAs);
    if (original.categoryID !== actual.categoryID || newItem)
        result.categoryID = actual.categoryID;
    if (original.type !== actual.type || newItem)
        result.type = actual.type;
    if (original.template !== actual.template || (newItem && !hasTemplate))
        result.template = actual.template;
    if (original.templateId !== actual.templateId || (newItem && hasTemplate))
        result.templateId = actual.templateId;
    if (original.deleted !== actual.deleted)
        result.deleted = actual.deleted;
    if (original.elementOwnerId !== actual.elementOwnerId || (newItem && !hasTemplate))
        result.elementOwnerId = actual.elementOwnerId;

    if (original.individualized !== actual.individualized)
        result.individualized = actual.individualized;

    for (const prp in actual.properties) {
        if (actual.properties.hasOwnProperty(prp)) {
            if (isNaN(+prp))
                continue;
            const prpId = +prp;
            if ((mode !== 'save' || !newItem || hasTemplate)
                && original.properties[prpId]
                && propertyValueEquals(original.properties[prpId], actual.properties[prpId]))
                continue;
            if (mode != 'keepResets'
                && (actual.properties[prpId].value == undefined || actual.properties[prpId].value == null)
                && !original.properties?.[prpId]?.value)
                continue;
            result.properties = {
                ...(result.properties || {}),
                [prpId]: fixTabsInProperty(actual.properties[prpId])
            }
        }
    }

    const addOnDiff = diffAddOns(actual.addOns, original.addOns);
    if (addOnDiff)
        result.addOns = addOnDiff;
    const elementsDif = diffElements(actual, original, mode);
    if (elementsDif)
        result.elements = elementsDif;

    for (const key in result) {
        if (result.hasOwnProperty(key)) {
            result.itemID = actual.itemID;
            if (original.version && !newItem)
                result.version = original.version;
            return result;
        }
    }
    return null;
}

function propertyValueEquals(a: PropertyValue, b: PropertyValue) {
    if (a.type !== b.type)
        return false;
    if (a.value === null && b.value === null)
        return true;
    else if (a.value === null || b.value === null)
        return false;
    switch (a.type) {
        case 'DATE':
            return (a.value as Date).getTime() === (b.value as Date).getTime();
        default:
            return a.value === b.value;
    }
}

function diffAddOns(actual?: AddOns, original?: AddOns): AddOns | null {
    const result: AddOns = {};

    if (!original && !actual)
        return null;

    const diffPart = <K extends keyof AddOns>(key: K, fn: (actual: NonNullable<AddOns[K]>, original?: AddOns[K]) => AddOns[K] | null) => {
        if (actual?.[key]) {
            result[key] = fn(actual[key] as NonNullable<AddOns[K]>, original?.[key]) || undefined;
        } else if (original?.[key])
            result[key] = {} as AddOns[K];
    }
    diffPart('user', diffUserAddOn);
    diffPart('report', diffReportAddOn);
    diffPart('series', diffSeriesAddOn);
    diffPart('series_instance', diffSeriesInstanceAddOn);

    if (result.report || result.series || result.series_instance || result.user)
        return result;
    return null;
}

function diffUserAddOn(actual: UserAddOn, original?: UserAddOn): UserAddOn | null {
    return diffAddOn(['enabled', 'userName', 'languageId', 'userAdmin', 'userWildcards', 'permissions'],
        actual, original);
}
function diffReportAddOn(actual: ReportAddOn, original?: ReportAddOn): ReportAddOn | null {
    return diffAddOn(['reportDefinitionId', 'documentPath', 'servletUrl', 'dataProvider', 'start', 'length', 'sort'],
        actual, original);
}
function diffSeriesInstanceAddOn(actual: SeriesInstanceAddOn, original?: SeriesInstanceAddOn): SeriesInstanceAddOn | null {
    return diffAddOn(['number', 'realized', 'seriesId'], actual, original);
}
function diffSeriesAddOn(actual: SeriesAddOn, original?: SeriesAddOn): SeriesAddOn | null {
    return diffAddOn(['showAs', 'modifyConfig', 'generationConfig', 'realizationConfig'], actual, original);
}
function diffAddOn<T>(keys: (keyof T)[], actual: T, original?: T): T | null {
    if (keys.reduce((changed, key) => changed || !isEqual(actual[key], original?.[key]), false))
        return actual;
    return null;
}
function isEqual(a: any, b: any) {
    if (typeof a !== 'object' || typeof b !== 'object')
        return a === b;
    for (const name of Object.getOwnPropertyNames(a))
        if (!isEqual(a[name], b[name]))
            return false;
    return Object.getOwnPropertyNames(a).length === Object.getOwnPropertyNames(b).length;
}

function diffElements(actualItem: Item, originalItem: Item, mode: diffMode): ElementList[] | null {
    const result: ElementList[] = [];
    const actual = actualItem.elements.filter(el => el.isIndividualized || actualItem.individualized);
    const original = originalItem.elements.filter(el => el.isIndividualized || originalItem.individualized);

    const flattenOriginal = original.reduce((res, curr) => [...res, ...curr.elements], [] as Item[]);
    const flattenActual = actual.reduce((res, curr) => [...res, ...curr.elements], [] as Item[]);
    const flattenResult: { [id: number]: Partial<Item>[] } = {};

    flattenActual.forEach(act => {
        const org = flattenOriginal.find(o => o.itemID === act.itemID);
        const diff: Partial<Item> | null = org ? itemDiff(org, act, mode) : act;
        if (diff)
            (flattenResult[act.categoryID] ?? (flattenResult[act.categoryID] = [])).push(diff);
    });

    original.forEach(list => {
        if (flattenResult[list.categoryId])
            result.push({
                categoryId: list.categoryId,
                elements: flattenResult[list.categoryId] as Item[],
                isIndividualized: true
            })
    });

    if (result.length)
        return result;
    return null;
}
