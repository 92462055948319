import React from 'react';
import Image from './Image';

type ButtonBoxProps = {
    iconName: string;
    label: string;
    className?: string;
    onClick?: () => void;
    onMouseMove?: () => void;
    onMouseOut?: () => void;
}

export function ImageBox({ iconName, label, className = '', onClick, onMouseMove, onMouseOut }: ButtonBoxProps) {
    return <div className={className} onClick={onClick} onMouseMove={onMouseMove} onMouseOut={onMouseOut}>
        <Image name={iconName} />
        <div>{label}</div>
    </div>
}
