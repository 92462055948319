import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { insertImageAddOn } from './pluginsTinyMCE/insertImg';
import { customUnlinkAddOn } from './pluginsTinyMCE/unlink';
import { PropertyControlProps } from '../PropertyControl';
import HyperLinkPopup from './HyperLinkPopup';
import TemplatePopup from './TemplatePopup';
import { tactin } from '../../../utils/TactinGlobals';
import { OpenItemEvent } from '../../../model/events/TactinEvents';
import './RichTextControl.css'

//For self hosting TinyMCE
import tinymce from 'tinymce/tinymce';

// // Default icons are required for TinyMCE 5.3 or above
import 'tinymce/icons/default';

// // A theme is also required
import 'tinymce/themes/silver';

// // Any plugins you want to use has to be imported
import 'tinymce/plugins/hr';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/table';
tinymce.PluginManager.add('insertImg', insertImageAddOn);
tinymce.PluginManager.add('unlink', customUnlinkAddOn);

export function RichTextControl({ label, value, readOnly, className = '', onChange }: PropertyControlProps) {
    const [hyperLinkPopupState, setHyperLinkPopupState] = useState(false);
    const [templatePopupState, setTemplatePopupState] = useState(false);
    const [viewer, setViewer] = useState(true);
    const keyref = useRef<HTMLDivElement>(null);
    let thisEditor = useRef<TinyMCEEditor>();
    const viewerArea = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (viewer) {
            const listener = (e: any) => {
                const currentElem = e.target as HTMLLinkElement;
                if (currentElem.localName === 'a' && currentElem.href.slice(0, 2) === 'id') {
                    e.preventDefault();
                    tactin()?.eventBus.notify(new OpenItemEvent().byItemId(+currentElem.href.slice(3)));
                }
            }
            viewerArea.current?.addEventListener('click', listener);
            // hide tinymce any elements
            const TinyMCEElements = document.querySelectorAll('#gtx-trans') as NodeListOf<HTMLDivElement>;
            TinyMCEElements.forEach((e) => e.style.display = 'none');
            return () => { viewerArea.current?.removeEventListener('click', listener) }
        }
    }, [viewer])

    const handleEditorChange = (content: string) => {
        onChange(content);
    }
    const setup = (editor: TinyMCEEditor) => {
        thisEditor.current = editor;
        
        editor.ui.registry.addButton('hyperlink', {
            icon: 'link',
            tooltip: 'create hyperlink',
            onAction: () => { setHyperLinkPopupState(true) }
        });
        editor.ui.registry.addButton('customTemplate', {
            icon: 'template',
            tooltip: 'create template',
            onAction: () => { setTemplatePopupState(true) }
        });
        editor.on('keydown', (e: KeyboardEvent) => {
            
            if (e.key === "F5" || (e.key === "r" && e.ctrlKey)){
                e.preventDefault();
                return;
            }
            
            const resume = document.dispatchEvent(new KeyboardEvent('keydown', {
                altKey: e.altKey,
                bubbles: true,
                cancelable: true,
                code: e.code,
                composed: e.composed,
                ctrlKey: e.ctrlKey,
                detail: e.detail,
                isComposing: e.isComposing,
                key: e.key,
                location: e.location,
                metaKey: e.metaKey,
                repeat: e.repeat,
                shiftKey: e.shiftKey,
            }));
            if (!resume)
                e.preventDefault();
        })
        editor.on('focusin', () => keyref.current?.click());
    };
    const insertLinkForhyperLink = (fn: (showAs?: string) => string) => {
        const selectedString = thisEditor.current?.selection.getContent();
        thisEditor.current?.insertContent(fn(selectedString));
    }
    const insertLinkForTemplate = (value: string) => {
        thisEditor.current?.insertContent(value);
    }
    const switchViewr = () => !readOnly && setViewer(!viewer);

    return (
        <div ref={keyref} className={`v-strech ${className}`}>
            <div className={'rich-text-label'}>{label}</div>
            {!readOnly && <div onClick={switchViewr} className={`toggle ${viewer ? 'toggelInView' : 'toggelInEdit'}`} />}
            {hyperLinkPopupState ? <HyperLinkPopup showPopup={setHyperLinkPopupState} insertLink={insertLinkForhyperLink} /> : null}
            {templatePopupState ? <TemplatePopup showPopup={setTemplatePopupState} insertLink={insertLinkForTemplate} /> : null}
            {!viewer && !readOnly ?
                <Editor
                    value={value ? value : ''}
                    disabled={readOnly}
                    init={{
                        skin_url: 'skins',
                        menubar: false,
                        statusbar: false,
                        plugins: [
                            'hr lists advlist table',
                            'insertImg unlink',
                        ],
                        toolbar2: [
                            ['undo', 'redo'],
                            ['fontselect'],
                            ['formatselect'],
                            ['bold', 'italic', 'underline', 'strikethrough'],
                            ['forecolor', 'backcolor'],
                            ['superscript', 'subscript'],
                            ['alignleft', 'aligncenter', 'alignright'],
                            ['outdent', 'indent'],
                            ['hr'],
                            ['bullist', 'numlist'],
                            ['insertImg', 'hyperlink', 'unlink', 'table', 'customTemplate'],
                            ['removeformat'],
                        ].map(s => s.join(' ')).join(' | '),
                        setup,
                    }}
                    onEditorChange={handleEditorChange} />
                : <div ref={viewerArea} dangerouslySetInnerHTML={{ __html: value }} className='viewerArea' />}
        </div>
    );
}
