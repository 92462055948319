import React from 'react';
import { EntityComboBox } from './EntityComboBox';
import { LabeledControl } from './LabeledControl';
import { BarePropertyControlProps, PropertyControlProps } from './PropertyControl'

export function BareCategoryControl(props: BarePropertyControlProps & { type: number }) {
    const { type, ...p } = props;
    const config = { handlerClass: 'ListDataViewer', dataProvider: 'SSDP_CATEGORY', wildcards: [`itemType:${type}`] }

    return <EntityComboBox {...p} configuration={config} noClearButton noActionButton />
}

export function CategoryControl(props: PropertyControlProps & { type: number }) {
    return <LabeledControl label={props.label} className={props.className} >
        <BareCategoryControl {...props} className='blockControl' />
    </LabeledControl>
}
