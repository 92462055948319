import { MessageNotification, QuestionNotification, SystemActionExecutor } from "../../components/systemactions/SystemAction";
import { api } from "../../utils/api/ApiProvider";
import { Failed } from "../../utils/api/TactinApi";
import { tactin } from "../../utils/TactinGlobals";
import { WithWaitNotify } from "../../utils/WaitNotification";
import { Item, SeriesAddOn } from "../item/ItemTypes";
import { ActionContextProvider, ItemActionContext } from "./ActionContext";

export function CreateSeriesSystemAction(contextProvider: ActionContextProvider): SystemActionExecutor {
    return async () => {
        const context = contextProvider('item');
        if (!context || !(context instanceof ItemActionContext))
            return MessageNotification('Nie udało się utworzyć serii.');
        const template = context.item;
        if (!template.item.template || template.item.addOns?.series)
            return MessageNotification('Nie udało się utworzyć serii.');

        const defaultConfig = tactin().configuration.itemSeries.defaultConfig(
            template.item.type,
            template.item.categoryID);
        const seriesAddOn: SeriesAddOn = {
            showAs: '',
            modifyConfig: defaultConfig.modifiedFields,
            generationConfig: defaultConfig.generationCondition,
            realizationConfig: defaultConfig.realizationCondition
        }

        if (template.item.addOns)
            template.item.addOns.series = seriesAddOn;
        else
            template.item.addOns = { series: seriesAddOn };

        return {
            type: 'reload',
            data: { ...template.item, addOns: { ...(template.item.addOns || {}), series: seriesAddOn } } as Item
        }
    }
}

export function RestoreSeriesSystemAction(contextProvider: ActionContextProvider): SystemActionExecutor {
    return async () => {
        const context = contextProvider('item');
        if (!context || context.id <= 0)
            return MessageNotification('Nie można odtworzyć niezapisanej serii.');

        const resume = async () => {
            try {
                const result = await WithWaitNotify(api().Item.restoreSeries(context.id), 'Odtwarzanie serii...');
                return MessageNotification(result.message);
            } catch (e) {
                if (e instanceof Failed)
                    return MessageNotification(e.message);
                throw e;
            }
        }
        return QuestionNotification('Przywrócenie serii spowoduje utratę '
            + 'wszystkich zmian dokonanych w niezrealizowanych instancjach, '
            + 'występujących po ostatniej zrealizowanej. Czy jesteś pewiem, '
            + 'że chcesz przywrócić serię?',
            resume);
    }
}
