import React, { useEffect, useState } from 'react';
import { Language } from '../../../model/ConfigurationEntities';
import { ShowCustomUI, ShowMessageEvent } from '../../../model/events/TactinEvents';
import { ClientItem, Item, UserAddOn } from '../../../model/item/ItemTypes';
import { HandleErrors } from '../../../utils/api/ApiErrorHandler';
import { api } from '../../../utils/api/ApiProvider';
import { tactin } from '../../../utils/TactinGlobals';
import { CardSubPanelWrapper } from '../CardSubPanelWrapper';
import './UserAddOnPanel.css';
import { UserPermissions } from './UserPermissions';
import { WildcardEditor } from './WildcardEditor';

type UserAddOnPanelProps = {
    original: ClientItem;
    itemData: ClientItem;
    configuration: any;
    onChange: (item: Item) => void;
}
export function UserAddOnPanel(props: UserAddOnPanelProps) {
    const addOn = props.itemData.item.addOns?.user;
    const tr = tactin().configuration.translate;

    const change = (v?: UserAddOn) => {
        props.onChange({ ...props.itemData.item, addOns: { ...(props.itemData.item.addOns || {}), user: v } })
    }

    const changePart = (v: Partial<UserAddOn>) => {
        if (addOn)
            change({ ...addOn, ...v });
    }

    const changePassword = () => {
        if (props.itemData.item.itemID >= 0 && props.original.item.addOns?.user)
            tactin()?.eventBus.notify(new ShowCustomUI('ChangePassword', { itemId: props.itemData.item.itemID }));
        else
            tactin()?.eventBus.notify(new ShowMessageEvent('Musisz zapisać użytkownika zanim będziesz mógł zmienić hasło.'));
    }

    let component = null;
    if (!addOn)
        component = <>
            <div>
                {tr('Dodanie możliwości logowania użytkownika do systemu jest '
                    + 'czynnością nieodwracalną. Jeśli dodasz możliwość logowania '
                    + 'się tego użytkownika może mieć to wpływ na wykorzystanie '
                    + 'licencji systemowej.')}
            </div>
            <button onClick={() => change({ userAdmin: false, enabled: true, languageId: 1, userName: '', userWildcards: [], permissions: [] })}>
                {tr('Dodaj możliwość logowania do systemu')}
            </button>
        </>
    else
        component = <>
            <label>
                <input type="checkbox" checked={addOn.enabled}
                    onChange={e => changePart({ enabled: !addOn.enabled })} />
                {tr('Użytkownik może logować się do systemu')}
            </label>
            <div className={`bordered-section`}>
                <div>
                    <span>{tr('Login użytkownika')}</span>
                    <input type="text" disabled={!addOn.enabled} value={addOn?.userName || ''}
                        onChange={e => changePart({ userName: e.currentTarget.value })} />
                </div>
                <div>
                    <span>{tr('Hasło')}</span>
                    <button disabled={!addOn.enabled} onClick={changePassword}>{tactin().configuration.translate('Zmień hasło')}</button>
                </div>
                <div>
                    <span>{tr('Język')}</span>
                    <LanguageSelector value={addOn.languageId} onChange={id => changePart({ languageId: id })} disabled={!addOn.enabled} />
                </div>
                <div>
                    <span>{tr('Wildcardy')}</span>
                    <WildcardEditor value={(addOn.userWildcards || [])} disabled={!addOn.enabled}
                        onChange={v => changePart({ userWildcards: v })} />
                </div>
                <div>
                    <span>{tactin().configuration.translate('Dostęp do panelu administratora')}</span>
                    <input type="checkbox" disabled={!addOn.enabled} checked={addOn?.userAdmin || false}
                        onChange={e => changePart({ userAdmin: !addOn.userAdmin })} />                    
                </div>
            </div>
            {(props.itemData.item.itemID >= 0 && props.original.item.addOns?.user)
                ? <UserPermissions itemId={props.itemData.item.itemID} permissions={addOn.permissions}
                    onChange={e => changePart({ permissions: e })} />
                : <div>{tr('Musisz zapisać użytkownika zanim będziesz mógł nadać mu uprawnienia.')}</div>}
        </>;

    return <CardSubPanelWrapper configuration={props.configuration}>
        {component}
    </CardSubPanelWrapper>
}

type LanguageSelectorProps = {
    value: number;
    onChange: (id: number) => void;
    disabled?: boolean;
    className?: string;
}
function LanguageSelector(props: LanguageSelectorProps) {
    const [languages, setLanguages] = useState<Language[]>([]);

    useEffect(() => {
        api().Language.getLanguages(false).then(setLanguages).catch(HandleErrors());
    }, []);

    return <select className={props.className}
        value={props.value}
        onChange={e => props.onChange(Number(e.currentTarget.value))}
        multiple={false}
        size={1}
        disabled={props.disabled} >
        {languages.map(lng => <option key={lng.id} value={lng.id}>{lng.name}</option>)}
    </select>
}
