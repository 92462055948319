import { Editor, TinyMCE } from 'tinymce';

export function insertImageAddOn(editor: Editor, url: string) {
    let srcDataKeeper = null as null | string | ArrayBuffer;
    const openDialog = function () {
        // creation input
        const editorParentElement = editor.editorContainer.parentElement;
        const inputLoader = document.createElement('input');
        inputLoader.type = 'file';
        inputLoader.name = 'image_uploads';
        inputLoader.accept = '.jpg, .jpeg, .png';
        inputLoader.style.display = 'none';
        editorParentElement?.appendChild(inputLoader);

        setTimeout(() => {
            const checkboxRemoteLocatione = document.querySelector('.checkbox_remoteLocation') as HTMLInputElement;
            const checkboxLoadFile = document.querySelector('.checkbox_loadFile') as HTMLInputElement;
            const tinyMceCheckboxRemoteLocatione = checkboxRemoteLocatione.parentElement?.parentElement?.previousElementSibling as HTMLInputElement;
            const tinyMceCheckboxLoadFile = checkboxLoadFile.parentElement?.parentElement?.previousElementSibling as HTMLInputElement;
            tinyMceCheckboxRemoteLocatione.style.display = 'none';
            tinyMceCheckboxLoadFile.style.display = 'none';
            checkboxRemoteLocatione.addEventListener('click', () => {
                tinyMceCheckboxRemoteLocatione?.querySelector('input')?.click();
            })
            checkboxLoadFile.addEventListener('click', () => {
                tinyMceCheckboxLoadFile?.querySelector('input')?.click();
            })
        }, 0)
        return editor.windowManager.open({
            title: 'Insert a picture',
            size: 'normal',
            body: {
                type: 'panel',
                items: [
                    {
                        type: 'checkbox',
                        name: 'checkbox_remoteLocation',
                        label: 'Remote location',
                    },
                    {
                        type: 'htmlpanel',
                        html: `<label class="container_checkbox">Remote location
                            <input type="radio" checked="checked" name="radio" class="checkbox_remoteLocation">
                            <span class="checkmark"></span>
                            </label>`,
                    },
                    {
                        type: 'input',
                        name: 'urlInput',
                    },
                    {
                        type: 'htmlpanel',
                        html: `<span style="height: 8px !important; display: block !important"></span>`,
                    },
                    {
                        type: 'checkbox',
                        name: 'checkbox_loadFile',
                        label: 'File',
                    },
                    {
                        type: 'htmlpanel',
                        html: `<label class="container_checkbox">File
                            <input type="radio" name="radio" class="checkbox_loadFile">
                            <span class="checkmark"></span>
                            </label>`
                    },
                    {
                        type: 'button',
                        text: 'Choose File',
                        name: 'chooser',
                        disabled: true
                    },
                    {
                        type: 'htmlpanel',
                        html: '<span class="insert_img_info" style="padding-left: 5px; color: #9b9b9b">No file chosen</span>'
                    },
                ]
            },
            buttons: [
                {
                    type: 'cancel',
                    text: 'Cancel'
                },
                {
                    type: 'submit',
                    text: 'Save',
                    primary: true
                }
            ],
            initialData: {
                checkbox_remoteLocation: true,
                checkbox_loadFile: false,
                urlInput: '',
                name: ''
            },
            onAction: (dialogApi, details) => {
                if (details.name === 'chooser') {
                    if (inputLoader) {
                        inputLoader.onchange = function () {
                            if (!inputLoader?.files?.length) return;
                            const file = inputLoader.files[0];
                            const reader = new FileReader();
                            reader.onload = function (e) {
                                if (e.target && inputLoader?.files?.length) {
                                    // size validation
                                    if (inputLoader.value && inputLoader.files[0].size > 1000000) {
                                        editor.windowManager.alert('File size should not exceed 1 Mb');
                                        dialogApi.setData({ name: '' });
                                        return null;
                                    }
                                    srcDataKeeper = reader.result;
                                    let info = inputLoader.files[0].name;
                                    if (info.length > 25) {
                                        const partOne = info.slice(0, 15);
                                        const partTwo = info.slice((info.length - 15), -1);
                                        info = `${partOne} ... ${partTwo}`;
                                    }
                                    const imgInfoElem = document.querySelector('.insert_img_info');
                                    if (imgInfoElem) imgInfoElem.innerHTML = info;
                                }
                            };
                            reader.readAsDataURL(file);
                        };
                    }
                    inputLoader.click();
                }
            },
            onChange: (dialogApi, details) => {
                const imgInfoElem = document.querySelector('.insert_img_info') as HTMLSpanElement;
                function launchOptionRemote() {
                    dialogApi.setData({ checkbox_loadFile: false });
                    dialogApi.setData({ checkbox_remoteLocation: true });
                    dialogApi.disable('chooser');
                    dialogApi.enable('urlInput');
                    imgInfoElem.style.color = '#9b9b9b';
                }
                function launchOptionLoading() {
                    dialogApi.setData({ checkbox_remoteLocation: false });
                    dialogApi.setData({ checkbox_loadFile: true });
                    dialogApi.disable('urlInput');
                    dialogApi.enable('chooser');
                    imgInfoElem.style.color = '#000000';
                }
                if (details.name === 'checkbox_remoteLocation')
                    launchOptionRemote();
                if (details.name === 'checkbox_loadFile')
                    launchOptionLoading();
            },
            onSubmit: (dialogApi) => {
                const formElements = dialogApi.getData();
                // url validation
                if (dialogApi.getData().checkbox_remoteLocation) {
                    if (!/[-a-zA-Z0-9@:%_\+.~#?&\/=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&\/=]*)?/gi
                        .test(formElements.urlInput)) {
                        editor.windowManager.alert('Insert value is not correct');
                        return
                    }
                }
                if (!srcDataKeeper && dialogApi.getData().checkbox_loadFile) {
                    editor.windowManager.alert('No file has been shosen');
                    return
                }
                const src = dialogApi.getData().checkbox_remoteLocation ? formElements.urlInput : srcDataKeeper;
                editor.insertContent(`<img src=${src} alt=''></img>`);
                dialogApi.close();
            },
            onClose: () => {
                srcDataKeeper = null;
                inputLoader.remove();
            }
        });
    };
    // Add a button that opens a window
    editor.ui.registry.addButton('insertImg', {
        icon: 'image',
        tooltip: 'Insert a picture',
        onAction: () => { openDialog() }
    });
    // Adds a menu item, which can then be included in any menu via the menu/menubar configuration
    editor.ui.registry.addMenuItem('insert picture', {
        text: 'Insert a picture',
        onAction: () => { openDialog() }
    });
}

export default (tinymce: Editor) =>
    (tinymce.editorManager as TinyMCE).PluginManager.add('insertImg', insertImageAddOn);
