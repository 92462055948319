export default class MutableRange
{
    start: number;
    length: number;

    constructor(start: number, length: number) {
        this.start = start;
        this.length = length;
    }

    get end(): number {
        return this.start + this.length - 1;
    }
    set end(e: number) {
        this.length = e - this.start + 1;
    }
    
    update(start: number, length: number)
    {
        this.start = start;
        this.length = length;
    }

    contains(val: number): boolean {
        return val >= this.start && val < this.start + this.length;
    }

    include(val: number, maxSize: number = 0): boolean {
        if(this.contains(val))
            return true;
        else if(val < this.start
                && (maxSize == 0 || this.start + this.length - val < maxSize))
            this.update(val, this.start + this.length - val);
        else if(val > this.start
                && (maxSize == 0 || val - this.start < maxSize))
            this.update(this.start, val - this.start);
        else
            return false;
        return true;
    }

    overlaps(r: MutableRange): boolean {
        if(this.start < r.start)
            return (this.start + this.length > r.start);
        else if(this.start > r.start)
            return (this.start < r.start + r.length);
        else
            return true;
    }

    normalize(maxIndex: number = Number.MAX_SAFE_INTEGER, minIndex: number = 0): MutableRange {
        let newStart = Math.max(minIndex, this.start);
        let newEnd = newStart + this.length;
        if(newEnd > maxIndex)
        {
            newEnd = maxIndex;
            newStart = Math.max(newEnd - this.length, minIndex);
        }
        this.update(newStart, Math.max(newEnd - newStart, 0));
        return this;
    }

    split(newLength: number): MutableRange[] {
        if(this.length < newLength)
            return [ new MutableRange(this.start, this.length) ];

        let size = Math.floor(this.length / newLength);
        if(this.length % newLength != 0)
            ++size;
        const result = Array(size);
        let lengthRest = length;
        for(let i = 0; i < size; ++i)
        {
            result[i] = new MutableRange(this.start + i * newLength,
                    Math.min(newLength, lengthRest));
            lengthRest -= newLength;
        }

        return result;
    }

    static around(val: number, length: number): MutableRange {
        return new MutableRange(val - Math.floor(length / 2), length);
    }
    static normalizeList(unordered: MutableRange[], maxIndex: number): MutableRange[] {
        const sorted = unordered.sort((a, b) => a.start - b.start);
        for(let i = 1; i < sorted.length; i++)
            sorted[i].normalize(maxIndex, sorted[i - 1].end + 1);
        return sorted.filter(r => r.start <= maxIndex);
    }
}
