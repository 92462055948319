import { api } from "../../../utils/api/ApiProvider";
import { ListDataResult } from "../../../utils/api/Results";
import { ColumnFilter } from "../ColumnFilter";
import { ListProperties } from "../HelperTypes";
import { CacheNode, MissingDataCallback } from "./CacheNode";
import { CacheNodeBlock } from "./CacheNodeBlock";
import DataCache, { ROW_KEY_DELIMITER } from "./DataCache";
import { Region } from "./Region";
import { RowData, RowParserFn } from "./RowParser";

export class GroupCacheNode extends CacheNode {

    constructor(parentRegion: GroupCacheNodeBlock | null, index: number, rowData: RowData,
        childCount: number, open: boolean, source?: DataCache) {
        super(parentRegion, index, rowData, childCount, open, source);
    }

    togglableColumn(): number {
        return this.level - 1;
    }
}

export class GroupCacheNodeBlock extends CacheNodeBlock {

    constructor(parent: CacheNode, start: number, rows: string[][],
        visibleStart: number, visibleRows: number, source: GroupDataCache,
        rowParser?: RowParserFn) {
        super(parent, start, rows, visibleStart, visibleRows, rowParser, source);
    }

    getNew(row: string[], rowParser?: RowParserFn, source?: DataCache): CacheNode {
        const rowData = rowParser ? rowParser(row) : { columns: [...row] };
        return new GroupCacheNode(this,
            this.start + this.rows.length,
            rowData,
            this.getChildCount2(row),
            source ? source.isOpen(rowData, this.parent) : false,
            source);
    }
}

export class GroupDataCache extends DataCache {

    grouping: string[];

    constructor(rowCount: number, grouping: string[], filters: ColumnFilter[], defaultCacheSegmentSize: number, openedSet: Map<string, number>, rowParser?: RowParserFn) {

        super(new GroupCacheNode(null, 0, { columns: [] }, rowCount, true), defaultCacheSegmentSize,  openedSet, rowParser);
        this.grouping = [...grouping];
        this.filters = [...filters];
    }

    getRowKey(row: RowData, parent?: CacheNode): string {

        let parentKey: string | null = null;
        let thisLevel = 1;
        if (parent && parent.rowData.columns.length) {
            thisLevel = parent.level + 1;
            const columnValue = parent.rowData.columns[parent.level - 1];
            if (typeof columnValue === 'object')
                parentKey = columnValue.meta;
            else
                parentKey = null;
        }

        let thisRow = ''
        if (row.columns.length >= thisLevel) {
            const colValue = row.columns[thisLevel - 1]
            thisRow = typeof colValue === 'string' ? colValue : colValue.showAs;
        }
        if (parentKey === null)
            return thisRow;
        else
            return parentKey + ROW_KEY_DELIMITER + thisRow;
    }

    getNewRegion(fold: CacheNode, start: number, rows: string[][], visibleStart: number): Region {
        if (fold.level !== this.grouping.length)
            return new GroupCacheNodeBlock(fold, start, rows, visibleStart, rows.length, this, this.rowParser);
        else
            return new RowCacheNodeBlock(fold, start, rows, visibleStart, rows.length, this.rowParser);
    }

    getGroupingValues(parent: CacheNode): string[] {
        if (parent.level > 0
            && parent.level <= parent.rowData.columns.length) {
            const key = parent.rowData.columns[parent.level - 1];
            if (typeof key === 'object')
                return key.meta.split(ROW_KEY_DELIMITER, -1);
        }
        return [];
    }

    getListData(parent: CacheNode, start: number, length: number): Promise<ListDataResult> {
        return api().List.getListEx({
            dataProviderId: this._dataProvider,
            wildcards: this._wildcards,
            filters: this._filters,
            sortColumn: this._sortColumn,
            sortDirection: this._sortDirection,
            start,
            limit: length,
            aggregations: new Map<string, string>(),
            grouping: this.grouping,
            groupingValues: this.getGroupingValues(parent)
        });
    }

    getListProperties(): Promise<ListProperties> {
        return api().List.getProperties({
            dataProviderId: this._dataProvider,
            wildcards: this._wildcards,
            filters: this.filters,
            grouping: this.grouping,
            skipColumns: false
        });
    }
}

export class RowCacheNodeBlock implements Region {
    parent: CacheNode;
    rows: RowData[];
    start: number;
    visibleStart: number;
    visibleRows: number;

    constructor(parent: CacheNode,
        start: number,
        rows: string[][],
        visibleStart: number,
        visibleRows: number,
        rowParser?: RowParserFn) {

        this.start = start;
        this.parent = parent;
        this.visibleStart = visibleStart;
        this.visibleRows = visibleRows;
        this.rows = [...rows.map(r => rowParser ? rowParser(r) : { columns: [...r] })];
    }

    getParentGroup(): CacheNode {
        return this.parent;
    }

    modVisibleStart(mod: number): void {
        this.visibleStart += mod;
    }

    getChildren(): CacheNode[] {
        return [];
    }

    getChildCount(): number {
        return this.rows.length;
    }

    setVisibleRows(mod: number, index: number): void {
        this.visibleRows += mod;
        if (this.parent !== null)
            this.parent.setVisibleRows(mod, this);
    }

    getData(start: number, length: number, loader: MissingDataCallback): RowData[] {
        const listStart = start - this.visibleStart;
        const listEnd = Math.min(listStart + length, this.rows.length);
        return this.rows.slice(listStart, listEnd).map(r => ({ ...r, columns: [...r.columns] }));
    }

    lastRow(): number {
        return this.start + this.rows.length;
    }

    merge(region: Region): boolean {
        if (!(region instanceof RowCacheNodeBlock))
            return false;

        const overlap_length = this.start + this.rows.length - region.start;
        let result = false;
        if (overlap_length === 0) {
            this.rows.push(...region.rows);
            this.visibleRows += region.visibleRows;
            result = true;
        }
        else if (region.start >= this.start && overlap_length > 0) {
            if (overlap_length < region.rows.length) {
                this.rows.push(...region.rows.slice(overlap_length, region.rows.length));
                if (overlap_length > region.rows.length / 2) //add the last visible elements
                    this.visibleRows += region.rows.length - overlap_length;
                else //substract the first visible elements
                    this.visibleRows += region.visibleRows - overlap_length;
            }
            result = true;
        }
        return result;
    }
}
