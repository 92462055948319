export type LocalEventListener = (type: string, data?: any) => void;
export type TLocalEventBus = {
    register: (handler: LocalEventListener) => (() => void);
    notify: (type: string, data?: any) => void;
}

export default class LocalEventBus implements TLocalEventBus {
    listeners: LocalEventListener[] = [];
    events: [string, any][] = [];
    sender: number = 0;

    register(handler: LocalEventListener) {
        for (const listner of this.listeners)
            if(listner === handler)
                throw new Error('Event listener registered twice!');
        this.listeners.push(handler);
        return () => this.unregister(handler);
    }

    notify(type: string, data?: any) {
        this.events.push([type, data]);
        this.sendNotifications();
    }

    private unregister(handler: LocalEventListener) {
        this.listeners = this.listeners.filter(l => l !== handler);
    }

    private sendNotifications() {
        if(this.sender || this.events.length === 0)
            return;
        this.sender = window.setTimeout(() => {
            const event = this.events.shift();
            if(event)
                for (const handler of this.listeners)
                    handler(...event);
            this.sender = 0;
            this.sendNotifications();
        }, 0);
    }
}
